import { createContext, ReactNode, useState, useEffect } from 'react'
import { setCookie, destroyCookie, parseCookies } from 'nookies'
import { toast } from 'react-toastify'
import { setupApiClient } from '../services/api'
import { useNavigate } from 'react-router-dom'

type AuthContextData = {
    user: UserProps
    isAuthenticated: boolean
    isPremium: boolean;
    diasUso: number;
    signIn: (credenciais: LoginProps) => Promise<void>
    signOut: () => Promise<void>
    updateUser: (data: UserProps) => void
}

type LoginProps = {
    email: string
    senha: string
}

type UserProps = {
    id: string
    nome: string
    email: string
    urlfoto?: string
    url_logo?: string
    plano?: PlanoProps 
}

type PlanoProps = {
    id: string;
    status: string
}

type AuthProviderProps = {
    children : ReactNode
}

export const AuthContext = createContext({} as AuthContextData)

export async function signOut(){
    try{
        destroyCookie(null,'@MinhaClinicaToken');
        localStorage.removeItem('@MinhaClinica')
    } catch(error) {
        console.log(error)
        toast.error('Erro ao deslogar!')
    }
}
    
export function AuthProvider({ children }: AuthProviderProps ){
    const navigate = useNavigate()

    const [user, setUser] = useState<UserProps>()
    const isAuthenticated = !!user
    const [diasUso, setDiasUso] = useState(0)
    const [isPremium, setIsPremium] = useState(false)

    useEffect( () => {
        async function getUser(){
            const cookie = parseCookies()
            const token = cookie['@MinhaClinicaToken']
            
            if (token){
                const dias = localStorage.getItem('@diasUsoMC')
                setDiasUso(Number(dias))
                
                const apiClient = setupApiClient();

                await apiClient.get('/me')
                .then( (response) => {
                    const { ID, NOME, EMAIL, URLFOTO, URL_LOGO, ASSINATURAS } = response.data;
                    
                    let data = {
                        id: ID,
                        nome: NOME,
                        email: EMAIL,
                        urlfoto: URLFOTO,
                        url_logo: URL_LOGO,
                        plano: {
                            id: ASSINATURAS[0]?.ID,
                            status: ASSINATURAS[0]?.STATUS
                        }
                    }

                    setUser(data)
                    setIsPremium(ASSINATURAS[0]?.STATUS === 'active')
                })
                .catch( () => {
                    signOut()
                    navigate('/')
                })
            } 
        }

        getUser();       

    },[])

    function updateUser(data: UserProps){
        setUser(data)
    }

    async function signIn({ email, senha }: LoginProps){
        try{
            const apiClient = setupApiClient();

            const response = await apiClient.post('/session', {
                email: email,
                password: senha
            })

            const { ID, NOME, DATA, URLFOTO, URL_LOGO, TOKEN, ASSINATURAS } = response.data;

            const dataNow = new Date().getTime()
            const userCriado = new Date(DATA).getTime()

            const msegundos = dataNow - userCriado 
            const dias = msegundos / (1000 * 3600 * 24)
            setDiasUso(dias)

            if(dias > 7 && ASSINATURAS[0]?.STATUS !== 'active'){
               toast.warn('Seu período de testes acabou! :(')
            }

            setCookie(null,'@MinhaClinicaToken', TOKEN, {
                maxAge: 60 * 60 * 24 * 30,
                path: "/"
            })

            localStorage.setItem('@MinhaClinica',ID)
            localStorage.setItem('@diasUsoMC',String(dias))

            setUser({
                id: ID,
                nome: NOME,
                email: email,
                urlfoto: URLFOTO,
                url_logo: URL_LOGO,
                plano: {
                    id: ASSINATURAS[0]?.ID,
                    status: ASSINATURAS[0]?.STATUS
                }
            })

            setIsPremium(ASSINATURAS[0]?.STATUS === 'active')
            toast.success(`Bem vindo de volta ${NOME} :)`)
            navigate('/dashboard')        

       } catch(error) {
            if(error.response.status === 400){
                toast.error('Email/senha inválidos!')
            } else {
                toast.error('Internal server error!')
                console.log(error.response)

            }
        }
    }

    return(
        <AuthContext.Provider value={ {user, isAuthenticated, isPremium, diasUso, signIn, signOut, updateUser } }>
            {children}
        </AuthContext.Provider>
    )
}