import './index.css'
import { FaRegLaughWink } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { AiFillHome, AiFillPhone } from 'react-icons/ai'
import { FaUserCheck, FaUserPlus } from 'react-icons/fa'
import { ImPriceTags } from 'react-icons/im'
import { FiMenu } from 'react-icons/fi'
import AnchorLink from 'react-anchor-link-smooth-scroll'

interface HeaderProps{
    openMenu: () => void;
}

export default function HeaderHome({ openMenu }: HeaderProps){
    const navigate = useNavigate()

    function home(){
        navigate('/')
    }

    return(
        <header className='containerHeader'>
            <main className='boxHeader'>
                <div className='contentMenu'>
                
                    <div className='btn-menu-mobile'>
                        <FiMenu size={35} color='#FFF' onClick={openMenu}/>
                    </div>

                    <motion.div animate={{ x:50 }} className='boxLogo' onClick={home}>
                        <FaRegLaughWink color='#FFF' size={60}/>
                        <span>Minha clínica</span>                      
                    </motion.div>

                    <nav className='boxMenu'>
                        <ul>
                            <li> <AiFillHome color="#FFF" size={15}/> <Link to='/'>Home</Link></li>
                            <li> <FaUserCheck color="#FFF" size={16}/><Link to='/login' target='_blank'>Login</Link></li>
                            <li> <FaUserPlus color="#FFF" size={16}/> <Link to='/cadastrouser' target='_blank'>Cadastre-se</Link></li>
                            <li> <ImPriceTags color="#FFF" size={16}/><AnchorLink href='#planos'>Planos e preços</AnchorLink></li>
                            <li> <AiFillPhone color="#FFF" size={16}/><AnchorLink href='#contato'>Contato</AnchorLink></li>
                        </ul>
                    </nav>
                </div>
            </main>
        </header>
    )
}