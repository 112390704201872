import { useNavigate } from 'react-router-dom'

export default function ItensOpcoes(){
    const navigate = useNavigate()

    return(
        <ul className='ul-itens'>
            <li>OPÇÕES GERAIS</li>

        </ul>
    )
}