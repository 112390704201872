import './index.css'

export default function Footer(){
    return(
        <footer className='boxfooter'>
            <div className='footer'>
                <span>COPYRIGHT © 2023 WGA Informática - Sistema Minha clínica - Versão <strong>1.1.0</strong> de 13/09/2023</span>
            </div>
        </footer>
    )
}