import { FormEvent, useState, useContext } from 'react'
import './index.css'
import { FaRegLaughWink } from 'react-icons/fa';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import { MdMarkEmailRead } from 'react-icons/md'
import { AiFillEyeInvisible } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';

export default function Login(){
    const { signIn } = useContext(AuthContext)
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [loading, setLoading] = useState(false)

    async function handleLogin(e: FormEvent ){
        e.preventDefault()

        if(email === '' || senha === ''){
            toast.warn('Preencha os campos!')
            return
        }

        try{
            setLoading(true)

            await signIn({
                email: email,
                senha: senha
            })

            setLoading(false)
        } catch(err){
            console.log(err)
            setLoading(false)
        }



    }
    
    return(
        <main className='boxContainer'>
            <div className='boxLogin'>

                <div className='boximg'>
                    <FaRegLaughWink color='#FFF' size={90}/>
                    <h3>Minha clínica</h3>
                    <span>Bem vindo(a) ao sistema</span>
                </div>

                <form className='boxform' onSubmit={handleLogin}>
                    <h4>Acesso ao sistema</h4>
                    
                    <div className='boxemail'>
                        <MdMarkEmailRead size={35} color='#FFF'/>
                        <Input
                            type='email'
                            placeholder='Seu email...'
                            value={email}
                            onChange={ (e) => setEmail(e.target.value)}
                            required
                        />
                    </div>

                    <div className='boxemail'>
                        <AiFillEyeInvisible size={35} color='#FFF'/>
                        <Input
                            type='password'
                            placeholder='Sua senha...'
                            value={senha}
                            onChange={ (e) => setSenha(e.target.value)}
                            required
                        />
                    </div>

                    <Button
                        type='submit'
                    >
                        { loading ? 'Validando...' : 'Acessar' }
                    </Button>

                    <Link to='/cadastrouser'>
                        Não tem uma conta! Cadastre-se
                    </Link>
                </form>
            </div>
        </main>
    )
}