import './index.css'
import { FaHourglassStart } from 'react-icons/fa';

export default function Loading(){
    return(
        <div className='container-loading'>
            <div className='loading'>
                <FaHourglassStart size={60} color='#555'/>
                <h2>Aguarde...</h2>
            </div>  
        </div>
    )
}