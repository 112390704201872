import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { requestProntuariosProps } from "../../pages/Prontuario";


type PdfProps = {
    nomeClinica: string
    caminhoLogo?: string 
    dadosPaciente: requestProntuariosProps[]
}

export default function GerarProntuarioPDF({ nomeClinica, caminhoLogo, dadosPaciente }: PdfProps){

    const data = new Date().toLocaleString('pt-BR',{timeZone:'America/Sao_Paulo'})

    pdfMake.vfs = pdfFonts.pdfMake.vfs

    pdfMake.createPdf({
        pageSize:'A4',
        pageMargins: [15,10,15,30],
        content:[
            {
                text: nomeClinica,
                alignment:'center',
                fontSize:18,
                style:'header',
                italics:true,
                bold:true
            },
            {
                text: 'Prontuário odontológico',
                marginBottom: 30,
                alignment:'center',
                fontSize:14,
                italics:true,
            },
            {
                text: 'Paciente',
                style: 'header',
                fontSize:11,
                italics:true
            },
            {
                text: dadosPaciente[0]?.CLIENTES?.NOME,
                fontSize:9,
            },
            {
                text: 'Atendimentos realizados',
                style: 'header',
                alignment:'center',
                marginTop:10,
                marginBottom:20,
                fontSize:11,
                italics: true
            }, 
            {
                style: 'tableExample',
                table: {
                    headerRows: 1,
                    body:[
                        [
                            {text: 'Data atendimento', style: 'tableHeader', fontSize:10}, 
                            {text: 'Tratamento', style: 'tableHeader', fontSize:10}, 
                            {text: 'Tratamento realizado', style: 'tableHeader', fontSize:10}, 
                            {text: 'Observação', style: 'tableHeader', fontSize:10}
                        ],
                        ...dadosPaciente.reverse().map( (dados) => [
                            {text: new Date(dados?.DATA_AGENDA).toLocaleString('pt-BR',{timeZone:'UTC'}), fontSize:8},
                            {text:`${dados?.TRATAMENTO}`, fontSize:8},
                            {text:`${dados?.TRATAMENTO_REALIZADO}`, fontSize:8},
                            {text:`${dados?.OBSERVACOES}`, fontSize:8},
                        ])
                    ]
                }
            },
        ],
        footer: function(currentPage, pageCount){
            return [
                {
                    text:`Solicitado em ${data}`,
                    alignment:'right',
                    fontSize:7,
                    margin:[0,-10,20,0]
                },
                {
                    text:'Sistema Minha clínica - pagina '+ currentPage.toString() + ' de ' + pageCount,
                    alignment:'right',
                    fontSize:7,
                    margin:[0,0,20,5]
                }
            ]
        }

    }).print();

}