
export const cepMask = ['99.999-999']
export const telMask = ['(99) 9999-9999', '(99) 9 9999-9999']
export const cpfMask = ['999.999.999-99','99.999.999/9999-99']
export const valMask = ['999.999,99']

export const emailTest = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i

export function converterTextoParaData(data: string){
    let newData = data.split('/')
    
    if(newData.length === 0){
        return null
    } else {
        return new Date(parseInt(newData[2]), parseInt(newData[1]) -1, parseInt(newData[0]))
    }
}

export function isNumber(value: string){
    const num = /^[0-9]+$/
    return num.test(value)
}

export function isFloat(value: string){
    const num = /^\d+,\d{2}$/
    return num.test(value)
}