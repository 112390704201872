import { useState, useContext } from 'react'
import './index.css'
import { IoMenu } from 'react-icons/io5'
import avatar from '../../assets/avatar.png'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { HiHome } from 'react-icons/hi'
import { RiCloseCircleFill } from 'react-icons/ri'
import OpcoesMenu from '../OpcoesMenu'

export default function Header(){
    const navigate = useNavigate()
    const { user } = useContext(AuthContext)
    const [openMenu, setOpenMenu] = useState(false)

    return(
        <header className="header">
            <div className='menusvg'>
                <button onClick={() => setOpenMenu(!openMenu)}>
                    { openMenu ? <RiCloseCircleFill size={40} color='#fff'/> : <IoMenu size={40} color='#fff'/>}
                </button>
                
                <div className='pipe'></div>
                
                <button onClick={() => navigate('/dashboard')}>
                    <HiHome size={35} color='#fff'/>
                </button>
                
                <div className='infouser'>
                    <span>Minha clínica</span>
                    <i>Olá {user?.nome}</i>
                </div>
            </div>

            <div className='boxavatar'>
                <img src={ user?.urlfoto ? user?.urlfoto : avatar} alt='Avatar' width={55} />
            </div>

            { openMenu && (
                <OpcoesMenu/>
            )}
        </header>
    )
}