import { FormEvent, useEffect, useState, ChangeEvent } from 'react'
import './index.css'
import { useParams } from 'react-router-dom'
import Header from "../../components/Header"
import Menu from '../../components/BarraLateral'
import Footer from '../../components/Footer'
import Loading from '../../components/Loading'
import { FaUsers } from 'react-icons/fa'
import img from '../../assets/avatar.png'
import { Input } from '../../components/Input'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { MdCancel } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { IoArrowBack } from 'react-icons/io5'
import { setupApiClient } from '../../services/api'
import { toast } from 'react-toastify'
import { mask, unMask } from 'remask'
import { cepMask, telMask, cpfMask } from '../../utils/utils'


export default function NovoPaciente(){
    const navigate = useNavigate()
    const { id } = useParams()

    const [placeHolder, setPlaceHolder] = useState('000.000.000-00')

    const [isLoading, setIsLoading] = useState(false)
    const [fotoPaciente, setFotoPaciente] = useState(null)
    const [dataCad, setDataCad] = useState('')
    const [data_atualizado, setData_atualizado] = useState('')
    const [url_foto, setUrl_Foto] = useState('')
    const [nome, setNome] = useState('')
    const [apelido, setApelido] = useState('')
    const [endereco, setEndereco] = useState('')
    const [numero, setNumero] = useState('')
    const [bairro, setBairro] = useState('')
    const [complemento, setComplemento] = useState('')
    const [cidade, setCidade] = useState('')
    const [cep, setCep] = useState('')
    const [ufSelected, setUfSelected] = useState('')
    const [telefone, setTelefone] = useState('')
    const [celular, setCelular] = useState('')
    const [tipoCliente, setTipoCliente] = useState('')
    const [cpf, setCPF] = useState('')
    const [rg, setRG] = useState('')
    const [nascimento, setNascimemto] = useState('')
    const [email, setEmail] = useState('')
    const [contato, setContato] = useState('')
    const [observacoes, setObservacoes] = useState('')
    
    useEffect( () => {

        async function getPaciente(){
            if(id){
                try{
                    const apiCliente = setupApiClient()

                    const response = await apiCliente.get('/detalhecliente',{
                        params:{
                            id: id
                        }
                    })

                    const { NOME, APELIDO, DATACADASTRO, ENDERECO, NUMERO,
                            BAIRRO, COMPLEMENTO, CIDADE, CEP, UF, TELEFONE,
                            CELULAR, TIPOCLIENTE, CPF, RG, NASCIMENTO, EMAIL,
                            CONTATO, OBSERVACOES, URL_FOTO, ATUALIZADO } = response.data

                    let dtNasc = NASCIMENTO !== null ? NASCIMENTO.substring(0,10) : ''
                     
                    setNome(NOME)
                    setApelido(APELIDO)
                    setDataCad(new Date(DATACADASTRO).toLocaleDateString('pt-br'))
                    setData_atualizado(ATUALIZADO === null ? '' : new Date(ATUALIZADO).toLocaleDateString('pt-br'))
                    setEndereco(ENDERECO)
                    setNumero(NUMERO)
                    setBairro(BAIRRO)
                    setComplemento(COMPLEMENTO)
                    setCidade(CIDADE)
                    setCep(CEP)
                    setUfSelected(UF)
                    setTelefone(TELEFONE)
                    setCelular(CELULAR)
                    setTipoCliente(TIPOCLIENTE)
                    setCPF(CPF)
                    setRG(RG)
                    setNascimemto(dtNasc)
                    setEmail(EMAIL)
                    setContato(CONTATO)
                    setObservacoes(OBSERVACOES)
                    setUrl_Foto(URL_FOTO)


                } catch(err){
                    console.log(err.response)
                    toast.error('Erro buscando dados do paciente!')
                }

            } else {
                setDataCad(new Date().toLocaleDateString('pt-br'))
            }
        }

        getPaciente()

    },[])

    function handleCancelar(){
        navigate('/pacientes')
    }

    async function handleSalvar(e: FormEvent){
        e.preventDefault()

        if(nome === ''){
            toast.warn('Digite nome do paciente!')
            return
        }

        try{
            setIsLoading(true)
            const apiClient = setupApiClient()

            let dtNasc = ''

            if (nascimento !== ''){
                const nasc = nascimento.split('-')
    
                if (nasc.length !== 0){
                    dtNasc = nasc[2]+'/'+nasc[1]+'/'+nasc[0]
                }
            }

            if(id){
                const req = new FormData()
                req.append('id', id)
                req.append('dataatualizado', new Date().toLocaleDateString('pt-br'))
                req.append('nome', nome)
                req.append('apelido', apelido)
                req.append('endereco', endereco)
                req.append('numero', numero)
                req.append('bairro', bairro)
                req.append('complemento', complemento)
                req.append('cidade', cidade)
                req.append('cep', cep)
                req.append('uf', ufSelected)
                req.append('telefone', telefone)
                req.append('celular', celular)
                req.append('tipocliente', tipoCliente)
                req.append('cpf', cpf)
                req.append('rg', rg)
                req.append('nascimento', dtNasc)
                req.append('email', email)
                req.append('contato', contato)
                req.append('observacoes', observacoes)

                if(fotoPaciente){
                    req.append('file', fotoPaciente)
                }

                await apiClient.put('/cliente', req)

                toast.success('Paciente atualizado!')
                navigate('/pacientes')
                setIsLoading(false)    
            } else {

                const req = new FormData()
                req.append('nome', nome)
                req.append('datacadastro', dataCad)
                req.append('apelido', apelido)
                req.append('endereco', endereco)
                req.append('numero', numero)
                req.append('bairro', bairro)
                req.append('complemento', complemento)
                req.append('cidade', cidade)
                req.append('cep', cep)
                req.append('uf', ufSelected)
                req.append('telefone', telefone)
                req.append('celular', celular)
                req.append('tipocliente', tipoCliente)
                req.append('cpf', cpf)
                req.append('rg', rg)
                req.append('nascimento', dtNasc)
                req.append('email', email)
                req.append('contato', contato)
                req.append('observacoes', observacoes)

                if(fotoPaciente){
                    req.append('file', fotoPaciente)
                }

                await apiClient.post('/cliente', req)

                toast.success('Paciente cadastrado!')
                navigate('/pacientes')
                setIsLoading(false)               
            }

        } catch(err){
            console.log(err.response)
            setIsLoading(false)    
        }
    }

    function handleFoto(e: ChangeEvent<HTMLInputElement>){

        if(!e.target.files){
            return
        }

        const img = e.target.files[0];

        if (!img){
            return;
        }

        if (img.type === 'image/jpeg' || img.type === 'image/png' || img.type === 'image/jpg'){
            setFotoPaciente(img)
            setUrl_Foto(URL.createObjectURL(img))
        }        
    }

    function onChangeCep(e: ChangeEvent<HTMLInputElement>){
        const originalValue = unMask(e.target.value)
        const maskedValue = mask(originalValue, cepMask)
        setCep(maskedValue)
    }

    function onChangeFone(e: ChangeEvent<HTMLInputElement>){
        const originalValue = unMask(e.target.value)
        const maskedValue = mask(originalValue, telMask)
        setTelefone(maskedValue)
    }

    function onChangeCel(e: ChangeEvent<HTMLInputElement>){
        const originalValue = unMask(e.target.value)
        const maskedValue = mask(originalValue, telMask)
        setCelular(maskedValue)
    }

    function onChangeTipo(e: ChangeEvent<HTMLSelectElement>){
        setTipoCliente(e.target.value)
        { e.target.value === 'FISICA' ? setPlaceHolder('000.000.000-00') : setPlaceHolder('00.000.000/0000-00') }
    }

    function onChangeCPF(e: ChangeEvent<HTMLInputElement>){
        const originalValue = unMask(e.target.value)
        const maskedValue = mask(originalValue, cpfMask)
        setCPF(maskedValue)
    }

    if(isLoading){
        return(
            <main>
                <Header/>
                <Menu/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }
    
    return(
        <main>
            <Header/>
            <Menu/>
            <section className='content'>
                <div className='div-home'>

                    <div className='div-container-btn'>
                        <div className='textheader'>
                            <button className='btnBack' onClick={handleCancelar}>
                                <IoArrowBack color='#555' size={25}/>
                            </button>
                            <FaUsers color='#555' size={25}/>
                            <h3>{id ? 'Editando cadastro' : 'Novo cadastro'}</h3> 
                        </div>               
                    </div>                
                
                    <form className='infopaciente' autoComplete='none' onSubmit={handleSalvar}>
                        <div className='boxdados'>
                            <label className='foto'>
                                <img src={url_foto ? url_foto : img} alt='Foto' width={90}/>
                                <input type='file' accept="image/png, image/jpeg, image/jpg" onChange={handleFoto}/> 
                            </label>


                            <div className='dados1'>
                                <label htmlFor="nome">Nome</label>
                                <Input
                                    style={ {width:'550px'} } 
                                    id='nome' 
                                    placeholder='Digite o nome'
                                    type='text'
                                    value={nome}
                                    onChange={ (e) => setNome(e.target.value)}
                                />
                        
                                <div className='dados2' style={ {marginLeft:'0px'} }>
                                    <div className='infoadd'>
                                        <label htmlFor="apelido">Apelido</label>
                                        <Input 
                                            id='apelido' 
                                            placeholder='Digite o apelido "opcional"'
                                            type='text'
                                            value={apelido}
                                            onChange={ (e) => setApelido(e.target.value)}
                                        />
                                    </div>

                                    <div className='infoadd'>
                                        <label htmlFor="data">Paciente desde</label>
                                        <Input 
                                            disabled={true}
                                            style={ {width:'100px', cursor:'not-allowed'} } 
                                            id='data' 
                                            placeholder='00/00/0000'
                                            type='text'
                                            value={dataCad}
                                        />                                
                                    </div>

                                    <div className='infoadd'>
                                        <label htmlFor="dataat">Última atualização</label>
                                        <Input 
                                            disabled={true}
                                            style={ {width:'120px', cursor:'not-allowed'} } 
                                            id='dataat' 
                                            placeholder='00/00/0000'
                                            type='text'
                                            value={data_atualizado}
                                        />                                
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='dados2'>
                            <div className='infoadd'>
                                <label htmlFor="end">Endereço</label>
                                <Input 
                                    id='end' 
                                    placeholder='Endereço'
                                    type='text'
                                    value={endereco}
                                    onChange={ (e) => setEndereco(e.target.value)}
                                />
                            </div>
                    
                            <div className='infoadd'>
                                <label htmlFor="num">Numero</label>
                                <Input 
                                    style={ {width:'70px'}}
                                    id='num' 
                                    placeholder='nº'
                                    type='text'
                                    value={numero}
                                    onChange={ (e) => setNumero(e.target.value)}
                                />
                            </div>

                            <div className='infoadd'>
                                <label htmlFor="bairro">Bairro</label>
                                <Input 
                                    style={ {width:'175px'}}
                                    id='bairro' 
                                    placeholder='Bairro'
                                    type='text'
                                    value={bairro}
                                    onChange={ (e) => setBairro(e.target.value)}
                                />
                            </div>

                            <div className='infoadd'>
                                <label htmlFor="comp">Complemento</label>
                                <Input 
                                    style={ {width:'100px'}}
                                    id='comp' 
                                    placeholder='Complemento'
                                    type='text'
                                    value={complemento}
                                    onChange={ (e) => setComplemento(e.target.value)}
                                />
                            </div>
                        </div>  

                        <div className='dados2'>
                            <div className='infoadd'>
                                <label htmlFor="cidade">Cidade</label>
                                <Input 
                                    style={ {width:'240px'}}
                                    id='cidade' 
                                    placeholder='Cidade'
                                    type='text'
                                    value={cidade}
                                    onChange={ (e) => setCidade(e.target.value)}
                                />
                            </div>
                    
                            <div className='infoadd'>
                                <label htmlFor="cep">CEP</label>
                                <Input 
                                    style={ {width:'90px'}}
                                    id='cep' 
                                    placeholder='00.000-000'
                                    type='text'
                                    value={cep}
                                    onChange={onChangeCep}
                                />
                            </div>

                            <div className='infoadd'>
                                <label htmlFor="uf">UF</label>
                                <select 
                                    style={ {width:'60px',borderColor:'#ccc'}}
                                    id='uf' 
                                    value={ufSelected}
                                    onChange={ (e) => setUfSelected(e.target.value)}
                                >
                                    <option value="AC">AC</option>
                                    <option value="AL">AL</option>
                                    <option value="AP">AP</option>
                                    <option value="AM">AM</option>
                                    <option value="BA">BA</option>
                                    <option value="CE">CE</option>
                                    <option value="ES">ES</option>
                                    <option value="GO">GO</option>
                                    <option value="MA">MA</option>
                                    <option value="MT">MT</option>
                                    <option value="MS">MS</option>
                                    <option value="MG">MG</option>
                                    <option value="PA">PA</option>
                                    <option value="PB">PB</option>
                                    <option value="PR">PR</option>
                                    <option value="PE">PE</option>
                                    <option value="PI">PI</option>
                                    <option value="RJ">RJ</option>
                                    <option value="RN">RN</option>
                                    <option value="RS">RS</option>
                                    <option value="RO">RO</option>
                                    <option value="RR">RR</option>
                                    <option value="SC">SC</option>
                                    <option value="SP">SP</option>
                                    <option value="SE">SE</option>
                                    <option value="TO">TO</option>
                                    <option value="DF">DF</option>
                                </select>
                            </div>

                            <div className='infoadd'>
                                <label htmlFor="fone">Telefone</label>
                                <Input 
                                    style={ {width:'120px'}}
                                    id='fone' 
                                    placeholder='(00) 0000-0000'
                                    type='text'
                                    value={telefone}
                                    onChange={onChangeFone}
                                />
                            </div>

                            <div className='infoadd'>
                                <label htmlFor="cel">Celular</label>
                                <Input 
                                    style={ {width:'120px'}}
                                    id='cel' 
                                    placeholder='(00) 0 0000-0000'
                                    type='text'
                                    value={celular}
                                    onChange={onChangeCel}
                                />
                            </div>
                        </div>  

                        <div className='dados2'>
                            <div className='infoadd'>
                                <label htmlFor="tipo">Tipo cliente</label>
                                <select 
                                    style={ {width:'150px',borderColor:'#ccc'}}
                                    id='tipo' 
                                    value={tipoCliente}
                                    onChange={onChangeTipo}
                                >
                                    <option value="FISICA">FISICA</option>
                                    <option value="JURIDICA">JURIDICA</option>
                                </select>
                            </div>
                    
                            <div className='infoadd'>
                                <label htmlFor="cpf">CPF / CNPJ</label>
                                <Input 
                                    style={ {width:'180px'}}
                                    id='cpf' 
                                    placeholder={placeHolder}
                                    type='text'
                                    value={cpf}
                                    onChange={onChangeCPF}
                                />
                            </div>

                            <div className='infoadd'>
                                <label htmlFor="rg">RG / IE</label>
                                <Input 
                                    style={ {width:'195px'}}
                                    id='rg' 
                                    placeholder='000000000'
                                    type='text'
                                    value={rg}
                                    onChange={ (e) => setRG(e.target.value)}
                                />
                            </div>

                            <div className='infoadd'>
                                <label htmlFor="nasc">Nascimento</label>
                                <Input 
                                    style={ {width:'120px'}}
                                    id='nasc' 
                                    placeholder='00/00/0000'
                                    type='date'
                                    value={nascimento}
                                    onChange={ (e) => setNascimemto(e.target.value)}
                                />
                            </div>
                        </div>  

                        <div className='dados2'>
                            <div className='infoadd'>
                                <label htmlFor="email">E-mail</label>
                                <Input 
                                    style={ {width:'350px',textTransform:'lowercase'}}
                                    id='email' 
                                    placeholder='nome@dominio.com'
                                    type='email'
                                    value={email}
                                    onChange={ (e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div className='infoadd'>
                                <label htmlFor="rg">Contato</label>
                                <Input 
                                    style={ {width:'327px'}}
                                    id='rg' 
                                    placeholder='Contato'
                                    type='text'
                                    value={contato}
                                    onChange={ (e) => setContato(e.target.value)}
                                />
                            </div>
                        </div>  

                        <div className='dados2'>
                            <div className='infoadd'>
                                <label htmlFor="obs">Observações</label>
                                <textarea 
                                    id='obs' 
                                    placeholder='Observações'
                                    value={observacoes}
                                    onChange={ (e) => setObservacoes(e.target.value)}
                                />
                            </div>
                        </div> 

                        <div className='boxbtn2'>
                            <button 
                                id='salvar'
                                type='submit'
                            >
                                <BsFillCheckCircleFill color='#FFF' size={15}/>
                                Salvar
                            </button>

                            <button onClick={handleCancelar}>
                                <MdCancel color='#FFF' size={15}/>
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </section>
            <Footer/>
        </main>
    )
}