import { useState, useEffect, ChangeEvent } from 'react'
import './index.css'
import { useNavigate } from 'react-router-dom'
import { FaUsers } from 'react-icons/fa'
import Header from "../../components/Header"
import BarraLateral from '../../components/BarraLateral'
import Footer from '../../components/Footer'
import { MdAdd } from 'react-icons/md'
import { FaEdit, FaTrash, FaFileMedical } from 'react-icons/fa'
import foto from '../../assets/semfoto.png'
import { Link } from 'react-router-dom'
import Loading from '../../components/Loading'
import { setupApiClient } from '../../services/api'
import ModalDelete from '../../components/ModalDelete'
import { toast } from 'react-toastify'
import { Input } from '../../components/Input'
import { BsListCheck } from 'react-icons/bs'
import ModalAnamnese from '../../components/ModalAnamnese'


export type PacientesProps = {
    ID: string
    URL_FOTO?: string
    NOME: string
    APELIDO?: string
    TELEFONE?: string
    CELULAR?: string
}

export default function ListaPaciente(){
    const navigate = useNavigate()

    const [isLoaging, setIsLoading] = useState(false)
    const [pacientes, setPacientes] = useState<PacientesProps[]>([])
    const [openModal, setOpenModal] = useState(false)
    const [deletePaciente, setDeletePaciente] = useState<PacientesProps>()
    const [refresh, setRefresh] = useState(false)
    const [openModalAnamnese, setOpenModalAnamnese] = useState(false)
    const [dadosPaciente, setDadosPaciente] = useState<PacientesProps>()

    useEffect( () => {

        async function getPacientes(){
            try{
                setIsLoading(true)

                const apiClient = setupApiClient()

                const response  = await apiClient.get('/clientes')

                let data = []
                response.data.map( (cliente: PacientesProps) => {
                    data.push({
                        ID: cliente?.ID,
                        URL_FOTO: cliente?.URL_FOTO,
                        NOME: cliente?.NOME,
                        APELIDO: cliente?.APELIDO,
                        TELEFONE: cliente?.TELEFONE,
                        CELULAR: cliente?.CELULAR
                    })
                })

                setPacientes(data)
                setIsLoading(false)

            } catch(err) {
                console.log(err)
                setIsLoading(false)
            }
        }

        getPacientes()

    },[refresh])

    function handleEdit(paciente: PacientesProps){
        navigate(`/novopaciente/${paciente?.ID}`)
    }

    function handleDelete(paciente: PacientesProps){
        setOpenModal(true)
        setDeletePaciente(paciente)
    }

    function handleAnamnese(paciente: PacientesProps){
        setDadosPaciente(paciente)
        setOpenModalAnamnese(true)
    }

    function handleProntuario(paciente: PacientesProps){
       navigate(`/prontuario/${paciente?.ID}`)
    }
    
    async function DeletePaciente(id: string){
        try{
            setIsLoading(true)
            const apiClient = setupApiClient()

            await apiClient.delete('/cliente',{
                params:{
                    id: id
                }
            })

            setRefresh(!refresh)
            setIsLoading(false)
            setOpenModal(false)
        } catch(err){
            console.log(err.response)
            toast.error('Erro excluindo paciente!')
            setIsLoading(false)
            setOpenModal(false)
        }
    }

    function PesquisaPaciente(e: ChangeEvent<HTMLInputElement>) {
        let filtrar: string, texto: string
        const pacientes = document.getElementById('gridview-body').getElementsByClassName('div-linha')

        filtrar = e.target.value.toUpperCase()

        for(let i=0; i < pacientes.length; i++){
            texto = pacientes[i].children[1].innerHTML

            if(texto.toUpperCase().indexOf(filtrar) > -1){
                pacientes[i].classList.remove('ocultarLinha')
            } else {
                pacientes[i].classList.add('ocultarLinha')  
            }
        }
    }

    function PesquisaApelido(e: ChangeEvent<HTMLInputElement>) {
        let filtrar: string, texto: string
        const pacientes = document.getElementById('gridview-body').getElementsByClassName('div-linha')

        filtrar = e.target.value.toUpperCase()

        for(let i=0; i < pacientes.length; i++){
            texto = pacientes[i].children[2].innerHTML

            if(texto.toUpperCase().indexOf(filtrar) > -1){
                pacientes[i].classList.remove('ocultarLinha')
            } else {
                pacientes[i].classList.add('ocultarLinha')  
            }
        }
    }


    if(isLoaging){
        return(
            <main>
                <Header/>
                <BarraLateral/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header/>
            <BarraLateral/>

            <section className='content'>
                <div className='div-home'>

                    <div className='div-container-btn'>
                        <div className='textheader'>
                            <FaUsers color='#555' size={25}/>
                            <h3>Você está em pacientes cadastrados</h3>   
                        </div>   
                        <div className='div-btn-novo'>
                            <Link to='/novopaciente'>
                                <MdAdd size={20} color='#FFF'/>
                                Novo
                            </Link>
                        </div>
                    </div>   
                    
                    <div className='div-container-filtro'>
                        <div className='div-campos'>
                            <div className='div-filtrar'>
                                <p>Pesquisar por nome</p>
                                <Input
                                    id='inputPesquisa'
                                    placeholder='Digite o nome...'
                                    onChange={(e) => PesquisaPaciente(e)}
                                    type='search'
                                />
                            </div>

                            <div className='div-filtrar'>
                                <p>Pesquisar por apelido</p>
                                <Input
                                    id='inputPesquisa'
                                    placeholder='Digite o apelido...'
                                    onChange={(e) => PesquisaApelido(e)}
                                    type='search'
                                />
                            </div>
                        </div>
                        <div></div>
                    </div>

                    <div className='div-gridview'>

                        <div className='gridview-header'>
                            <span className='coluna c1'>FOTO</span>
                            <span className='coluna c2'>NOME</span>
                            <span className='coluna c3'>APELIDO</span>
                            <span className='coluna c4'>TELEFONE</span>
                            <span className='coluna c5'>CELULAR</span>
                            <span className='coluna c6'>AÇÕES</span>
                        </div>

                        <div className='gridview-body' id='gridview-body'>
                        { pacientes.map( (paciente) => {
                            return(
                                <div key={paciente?.ID} className='div-linha'>
                                    <span className='linha c1'>
                                        <img src={paciente?.URL_FOTO ? paciente?.URL_FOTO : foto} alt='Foto' width={30} />
                                    </span>  
                                    <span className='linha c2 align'>{paciente?.NOME}</span>
                                    <span className='linha c3 align'>{paciente?.APELIDO}</span>
                                    <span className='linha c4'>{paciente?.TELEFONE}</span>
                                    <span className='linha c5'>{paciente?.CELULAR}</span>
                                    <span className="linha c6">
                                        <button className='btn-acao' title='EDITAR' onClick={ () => handleEdit(paciente)}>
                                            <FaEdit size={16} color='#4E9438'/>
                                        </button>
                                        <button className='btn-acao' title='EXCLUIR' onClick={ () => handleDelete(paciente)}>
                                            <FaTrash size={16} color='#FF0000'/>
                                        </button>
                                        <button className='btn-acao' title='FICHA ANAMNESE' onClick={ () => handleAnamnese(paciente)}>
                                            <BsListCheck size={16} color='#000'/>
                                        </button>
                                        <button className='btn-acao' title='PRONTUÁRIO MÉDICO' onClick={ () => {handleProntuario(paciente)}}>
                                            <FaFileMedical size={16} color='#2322AE'/>
                                        </button>
                                    </span>

                                </div>
                            )
                        })}
                        </div>
                    </div>
                </div>
            </section>   

            { openModal && (
                <ModalDelete
                    data={deletePaciente}
                    onClose={ () => setOpenModal(!openModal)}
                    onRequest={ (id: string) => DeletePaciente(id)}
                />
            )}
            
            { openModalAnamnese && (
                <ModalAnamnese
                    onClose={ () => setOpenModalAnamnese(false)}
                    data={dadosPaciente}
                />
            )}

            <Footer/>
        </main>
    )
}

