import './index.css'
import { CgSmileSad } from 'react-icons/cg'

export default function Notfound(){
    return(
        <div className='container404'>

            <CgSmileSad size={100} color='#2322AE'/>
            <span>Minha clínica</span>

            <div className='boxNotfound'>
                <h1>404</h1>
                <span>|</span>
                <h3>Página não encontrada</h3>
            </div>
        </div>
    )
}