import './index.css'
import { useNavigate } from 'react-router-dom'

export default function ItensCadastro(){
    const navigate = useNavigate()
    
    return(
        <ul className='ul-itens'>
            <li onClick={() => navigate('/pacientes')}>CADASTRO DE PACIENTES</li>

        </ul>
    )
}