import { useState, useEffect, ChangeEvent } from 'react'
import './index.css'
import { VscCalendar } from 'react-icons/vsc'
import { IoChevronBack } from 'react-icons/io5'
import { AiOutlineFieldTime, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { FaHourglassStart } from 'react-icons/fa'
import { CgSmileSad } from 'react-icons/cg'
import { BiTimer } from 'react-icons/bi'
import { Button } from '../../components/Button'
import Collapse from '../../components/Collapse'
import { BiChevronDown } from 'react-icons/bi'
import ModalAgenda from '../../components/ModalAgenda'
import Loading from '../../components/Loading'
import Header from '../../components/Header'
import BarraLateral from '../../components/BarraLateral'
import Footer from '../../components/Footer'
import { toast } from 'react-toastify'
import { setupApiClient } from '../../services/api'

type arrAgendaProps = {
    ID: string,
    USUARIOID: string
    CLIENTEID: string
    DATA_AGENDA: string
    TRATAMENTO: string
    OBSERVACOES: string
    CLIENTES: ClienteProps
}

type ClienteProps = {
    NOME: string 
}   

type RequestDiasAtendimentoProps = {
    DATA_AGENDA: string
}

export default function Agenda(){
    const [isLoaging, setIsLoading] = useState(false)
    const [isloadingHorario, setIsLoadingHorario] = useState(false)
    const [diaAgenda, setDiaAgenda] = useState('')
    const [arrAgenda, setArrAgenda] = useState<arrAgendaProps[]>([])
    const [openModalAgenda, setOpenModalAgenda] = useState(false)
    const [diaAgendarNovo, setDiaAgendarNovo] = useState('')
    const [diasAgendamento, setDiasAgendamento] = useState([])
    const meses = ['JANEIRO', 'FEVEREIRO', 'MARÇO', 'ABRIL', 'MAIO', 'JUNHO', 'JULHO', 'AGOSTO', 'SETEMBRO', 'OUTUBRO', 'NOVEMBRO', 'DEZEMBRO']
    const semana = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB']
    const diaAtual = new Date()
    diaAtual.setDate(1) 

    const [dataHora, setDataHora] = useState(new Date(diaAtual))
    let mes = dataHora.getMonth()
    let ano = dataHora.getFullYear()

    useEffect( () => {
        pintaMesAtual()
        imprimeMesCorrente()
        getDiasAtendimento(dataHora.getDate()+'/'+(dataHora.getMonth()+1)+'/'+dataHora.getFullYear())
    },[dataHora])

    useEffect( () => {
        const span = document.querySelectorAll('tbody tr td span')
        const tdHoje = document.querySelector('.hoje')

        tdHoje.classList.remove('atendimento')

        span.forEach( (sp) => {

            if(diasAgendamento.find( (dia) => dia == sp.innerHTML)){
                sp.classList.add('atendimento')
            }
        })

    },[diasAgendamento])


    function pintaMesAtual(){
        for(let i = 0; i <= 11; i++){
            let lis = document.getElementById(i.toString())
            lis.classList.remove('class','mesatual')
        }

        let li = document.getElementById(mes.toString())
        li.setAttribute('class','mesatual')
    }

    
    
    function pegaUltimoDiaMes(data: Date){
        let dataCopia = new Date(data)
        dataCopia.setMonth(data.getMonth() +1)
        dataCopia.setDate(1)
        dataCopia.setDate(dataCopia.getDate() -1)
        return dataCopia.getDate()
    }

    function handleVoltaMes(){
        let dataCopia = new Date(dataHora)
        dataCopia.setMonth(dataHora.getMonth() - 1)
        dataCopia.setDate(1) 
        setDataHora(dataCopia)       
    }

    function handleAvancaMes(){
        let dataCopia = new Date(dataHora)
        dataCopia.setMonth(dataHora.getMonth() + 1)
        dataCopia.setDate(1) 
        setDataHora(dataCopia)   
    }

    async function getAgenda(dia: string){
        try{
            setIsLoadingHorario(true)
            const apiClient = setupApiClient()
            
            const response = await apiClient.get('/agenda',{
                params:{
                    data: dia
                }
            })

            setArrAgenda(response.data)
            setIsLoadingHorario(false)

        } catch(err){
            console.log(err.data)
            setIsLoadingHorario(false)
            toast.error('Erro buscando informações da agenda!')
        }
    }

    async function getDiasAtendimento(data: string){
        try{
            const apiClient = setupApiClient()

            const response = await apiClient.get('/dias-atendimento',{
                params:{
                    data: data
                }
            })

            setDiasAgendamento([])    
            let dias = []
            response.data.map( (dia: RequestDiasAtendimentoProps) => {
                dias.push(
                    Number(dia.DATA_AGENDA.substring(8,10))
                )
            })

            setDiasAgendamento(dias)

        } catch(err){
            console.log(err.data)
            toast.error('Erro buscando os dias de atendimento!')
        }
    }

    async function handleDia(dia: string){
        let mesCorrent = mes + 1
        let mesString = ''

        if(mesCorrent < 10){
            mesString = '0'+mesCorrent   
        } else {
            mesString = mesCorrent.toString()
        }

        if(Number(dia) < 10 && dia.length < 2){
            dia = '0'+dia
        }

        // if(new Date(ano,Number(mesString),Number(dia)) < new Date()){
        //     return
        // }

        setDiaAgenda(dia+'/'+mesString+'/'+ano)
        getAgenda(dia+'/'+mesString+'/'+ano)
    }

    function handleOpenModal(){
        const convertDate = diaAgenda.split('/')
        setDiaAgendarNovo(convertDate[2]+'-'+convertDate[1]+'-'+convertDate[0])
        setOpenModalAgenda(true)
    }

    function handleBuscaMes(mes: number){
        let dataCopia = new Date(dataHora)
        dataCopia.setMonth(mes)
        dataCopia.setDate(1) 
        setDataHora(dataCopia)  
    }


    function imprimeMesCorrente(){
        let removeBody = document.querySelector('tbody')
        if(removeBody){
            removeBody.remove()
        }
        
        const table = document.querySelector('table')
        const tbody = document.createElement('tbody')
        tbody.setAttribute('class','body-calendar')

        let trSemana = document.createElement('tr')
        const hoje = new Date().getDate()        
        const comeciaNoDia = dataHora.getDay()
        let nDia = 1
        let i = 1

        while(nDia <= pegaUltimoDiaMes(dataHora)){
            let dia = document.createElement('td')

            if(i <= comeciaNoDia){
                dia.innerText = ''
            } else {
                let span = document.createElement('span')
                span.innerText = nDia < 10 ? '0'+nDia.toString() : nDia.toString()

                if(nDia === hoje){
                    span.setAttribute('class','hoje')
                }

                span.onclick = () => handleDia(span.innerText)
                dia.appendChild(span)
                nDia++
            }

            trSemana.appendChild(dia)

            if(i % 7 === 0){
                tbody.appendChild(trSemana)
                trSemana = document.createElement('tr')
            }

            i++

        }

        tbody.appendChild(trSemana)
        table.appendChild(tbody)
    }


    if(isLoaging){
        return(
            <main>
                <Header/>
                <BarraLateral/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header/>
            <BarraLateral/>
            <section className='content'>
                <div className='div-home'>

                    <div className='div-container-btn'>
                        <div className='textheader'>
                            <VscCalendar size={25} color='#555'/>
                            <h3>Você está em agenda de atendimento</h3>
                        </div>               
                    </div>
                    {/* inicio dos meses esquerda */}
                    <div className='div-container-agenda'>
                        <div className='div-meses'>
                            <div className='titulo-meses'>
                                <span>{ano}</span>
                            </div> 
                            <div className='meses'>
                                <ul>
                                    {meses.map( (mes, index) => (
                                        <li id={index.toString()} key={index} onClick={ () => handleBuscaMes(index)}>{mes}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        {/* inicio do calendario */}
                        <div className='div-calendar'>
                            <div className='titulo-mes'>
                                <IoChevronBack size={30} color='#000' onClick={handleVoltaMes}/>
                                <span>{meses[mes]} de {ano}</span>
                                <IoChevronBack size={30} color='#000' onClick={handleAvancaMes}/>
                            </div> 

                            <div className='div-gridview-calendar'>
                                <table className='table-grid'>
                                    <thead className='table-head'>
                                        <tr>
                                            { semana.map( (semana, index) => (
                                                <td key={index}>{semana}</td>
                                            ))}
                                        </tr>
                                    </thead>
                                    {/* <tbody className='body-calendar'>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><span>1</span></td>
                                            <td><span>2</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>3</span></td>
                                            <td><span>4</span></td>
                                            <td><span>5</span></td>
                                            <td><span>6</span></td>
                                            <td><span>7</span></td>
                                            <td><span>8</span></td>
                                            <td><span>9</span></td>
                                        </tr>
                                    </tbody> */}
                                </table>                             
                            </div>
                        </div>

                        <div className='div-agenda-pacientes'>

                            <div className='div-horario-paciente'>
                                <AiOutlineFieldTime size={28} color='#000'/>
                                <span>Agenda do dia {diaAgenda}</span>
                                <Button onClick={handleOpenModal}>
                                    <AiOutlineUsergroupAdd size={20} color='#fff'/>
                                    <span>Agendar</span>
                                </Button>
                            </div>

                            { isloadingHorario ? (
                                <div className='request-horario'>
                                    <div className='isloading'>
                                        <FaHourglassStart size={60} color='#555'/>
                                        <h4>Carregando...</h4>
                                    </div>
                                </div>
                            ) : ( 
                                <div className='div-lista-pacientes'>
                                    <ul>
                                        {arrAgenda.length > 0 ? arrAgenda.map( (atendimento) => {
                                            return(
                                                <li className='li-paciente' key={atendimento?.ID}>
                                                    <div className='det-paciente'>
                                                        <BiTimer id='timer' size={24} color='#000'/>
                                                        <Collapse
                                                            id={atendimento?.ID}
                                                            dataAgenda={diaAgenda}
                                                            horario={atendimento?.DATA_AGENDA.substring(11,16)}
                                                            nome={atendimento?.CLIENTES?.NOME}
                                                            tratamento={atendimento?.TRATAMENTO}
                                                            observacoes={atendimento?.OBSERVACOES}
                                                            onRequestDia={(dia) => handleDia(dia)}
                                                        />
                                                    </div>
                                                    <BiChevronDown id='down' size={20}/>
                                                </li>
                                            )
                                        }) : (
                                            <li>
                                                <div className='agenda-vazia'>
                                                    <CgSmileSad size={70} color='#bbb'/>
                                                    <span>Nenhum paciente agendado nesse dia!</span>    
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            { openModalAgenda && (
                <ModalAgenda
                    date={diaAgendarNovo}
                    onClose={ () => setOpenModalAgenda(false)}
                />                                
            )}
            <Footer/>
        </main>
    )
}