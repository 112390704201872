import { useNavigate } from 'react-router-dom'

export default function ItensAgenda(){
    const navigate = useNavigate()

    return(
        <ul className='ul-itens'>
            <li onClick={() => navigate('/agenda')}>AGENDA DE PACIENTES</li>

        </ul>
    )
}