import './index.css'
import React, { useState } from 'react'
import Collapsible from 'react-collapsible'
import ModalAgenda from '../ModalAgenda'
import { setupApiClient } from '../../services/api'
import { AtendimentoProps } from '../ModalAgenda'
import { toast } from 'react-toastify'
import ModalFinalizar from '../ModalFinalizar'

interface CollapseProps{
    id: string
    dataAgenda: string
    horario: string
    nome: string
    tratamento?: string
    observacoes?: string
    onRequestDia?: (dia: string) => Promise<void>
}

export default function Collapse({ id, dataAgenda, horario, nome, tratamento, observacoes, onRequestDia }: CollapseProps){
    const [open, setOpen] = useState(false)
    const [consulta, setConsulta] = useState<AtendimentoProps>()
    const [cancelar, setCancelar] = useState(false)
    const [modalFinalizar, setModalFinalizar] = useState(false)

    function handleFinalizar(){
        setModalFinalizar(true)
    }
    
    async function handleEditar(id: string){
        try{
            const apiClient = setupApiClient()
    
            const response = await apiClient.get('/atendimento',{
                params:{
                    id: id
                }
            })
    
            setConsulta(response.data)
            setOpen(true)
    
        } catch(err){
            console.log(err.data)
            toast.error('Erro buscando dados do atendimento!')
        }

    }

    function handleCancelar(){
        setCancelar(!cancelar)
    }

    async function cancelarAtendimento(id: string){
        try{
            const apiClient = setupApiClient()

            const response = await apiClient.delete('/atendimento',{
                params:{
                    id: id
                }
            })

            onRequestDia(response.data?.DATA_AGENDA.substring(8,10))
            toast.success('Atendimento cancelado!')
        } catch(err){
            console.log(err.data)
            toast.error('Erro cancelando atendimento!')
        }
    }

    return(
        <>
            <Collapsible 
                triggerStyle={{fontWeight:'bold', fontSize:'15px'}} 
                trigger={horario+' - '+nome}
                onClose={() => setCancelar(false)}
            >
                <p className='tratamento'>
                    <strong>Tratamento: </strong>{tratamento}
                </p>
                <p className='tratamento'>
                    <strong>Observações: </strong>{observacoes}
                </p>
                
                <button className='btn-finalizar' onClick={handleFinalizar}>
                    Finalizar
                </button>

                <button className='btn-editar' onClick={() => handleEditar(id)}>
                    Editar
                </button>
                
                <button className='btn-sim' onClick={handleCancelar}>
                    Cancelar
                </button>

                { cancelar && (
                    <div className='cancelar-atendimento'>
                        <span>Deseja cancelar esse atendimento?</span>
                        <div>
                            <button className='btn-sim' onClick={() => cancelarAtendimento(id)}>
                                SIM
                            </button>
                            <button className='btn-nao' onClick={handleCancelar}>
                                NÃO
                            </button>
                        </div>
                    </div>
                )}
            </Collapsible>

            { open && (
                <ModalAgenda
                    date={consulta?.DATA_AGENDA.substring(0,10)}
                    onClose={ () => setOpen(false)}
                    atendimentoID={consulta}
                />
            )}

            { modalFinalizar && (
                <ModalFinalizar
                    id={id}
                    dataAgenda={dataAgenda}
                    nomePaciente={nome}
                    onClose={() => setModalFinalizar(false)}
                    onRequestAtualizar={(dia) => onRequestDia(dia)}
                />
            )}
        </>
    )
}