import { useState, ChangeEvent } from 'react'
import './index.css'
import { FaWindowClose } from 'react-icons/fa'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { TextArea, Input } from '../Input'
import { toast } from 'react-toastify'
import { BiLoaderCircle } from 'react-icons/bi'
import { setupApiClient } from '../../services/api'
import { isFloat, isNumber } from '../../utils/utils'

interface ModalFinalizarProps{
    id: string
    nomePaciente: string
    dataAgenda:string
    onClose: () => void
    onRequestAtualizar?: (dia: string) => Promise<void>
}

export default function ModalFinalizar({ id, nomePaciente, dataAgenda, onClose, onRequestAtualizar }: ModalFinalizarProps){

    let newData = dataAgenda.split('/')
    const diaRequest = newData[0]
    const [formaPagamento, setFormaPagamento] = useState(['DINHEIRO','CHEQUE','PIX','CARTÃO CRÉDITO','CARTÃO DÉBITO','TRANSFERÊNCIA','CARTEIRA'])
    const [formaPagtoSelecionada, setFormaPagtoSelecionada] = useState(0)
    const [valorCobrado, setValorCobrado] = useState('')
    const [parcelas, setParcelas] = useState(['1x','2x','3x','4x','5x','6x','7x','8x','9x','10x','11x','12x'])
    const [parcelaSelecionada, setParcelaSelecionada] = useState(0)
    const [dataVencimento, setDataVencimento] = useState(newData[2]+'-'+newData[1]+'-'+newData[0])
    const [obsPagamento, setObsPagamento] = useState('')
    const [tratamentoRealizado, setTratamentoRealizado] = useState('')
    const [loadingFinalizar, setLoadingFinalizar] = useState(false)

    function handleFormaSelecionada(e){
        setFormaPagtoSelecionada(e.target.value)
    }

    function handleParcelaSelecionada(e){
        setParcelaSelecionada(e.target.value)
    }

    async function handleFinalizarAtendimento(){
        if(tratamentoRealizado == ''){
            toast.warn('Informe o tratamento realizado!')
            return
        }

        if(valorCobrado == ''){
            toast.warn('Informe o valor cobrado!')
            return
        }

        if(!isNumber(valorCobrado) && !isFloat(valorCobrado)){
            toast.warn('Valor cobrado inválido!')
            return            
        }

        try{
            setLoadingFinalizar(true)
            const apiClient = setupApiClient()

            const resp = await apiClient.put('/agenda-finalizar',{
                id: id,
                tratamento: tratamentoRealizado,
                formapagto: formaPagamento[formaPagtoSelecionada],
                valorcobrado: valorCobrado.toString().replace(',','.'),
                datavcto: dataVencimento,
                obspagto: obsPagamento,
                parcelas: Number(parcelaSelecionada) + 1
            })

            if(resp.status == 208){
                toast.warn('Esse atendimento já foi finalizado! :)')  
            } else {
                toast.success('Atendimento finalizado! :)')
            }

            setLoadingFinalizar(false)
            onRequestAtualizar(diaRequest)
            onClose()

        } catch(err){
            toast.error('Erro finalizando atendimento! :(')
            console.log(err.data)
            setLoadingFinalizar(false)
        }
    }

    return(
        <section className='container-finalizar'>
            
            <div className='content-finalizar'>
                <div className='grupo-btn'>
                    <button id='fechar-modal' onClick={ () => onClose()}>
                        <FaWindowClose size={20} color='#FFF'/>
                    </button>
                
                    <button id='finalizar-atend' onClick={handleFinalizarAtendimento}>
                        <BsFillCheckCircleFill size={20} color='#FFF'/>
                        Finalizar
                    </button>
                    
                    { loadingFinalizar && (
                        <div className='loading-finalizar'>
                            <BiLoaderCircle color='#000' size={25}/>
                            <span>Finalizando atendimento...</span>
                        </div>
                    )}
                </div>


                <div className='boxcontent-dados'>
                    <div className='dados-paciente'>
                        <small>Paciente</small>
                        <span>{nomePaciente}</span>
                    </div>

                    <div className='trat-realizado'>
                        <label htmlFor='txt-realizado'>Tratamento realizado</label>
                        <TextArea
                            id='txt-realizado'
                            placeholder='Oque foi realizado?'
                            value={tratamentoRealizado}
                            onChange={ (e) => setTratamentoRealizado(e.target.value)}
                        />
                    </div>

                    <div className='container-valores'>
                        <div className='valor-cobrado'>
                            
                            <div className='dv-valor'>
                                <label htmlFor='vl-cobrado'>Valor cobrado</label>
                                <Input
                                    id='vl-cobrado'
                                    type='text'
                                    placeholder='R$ 0,00'
                                    value={valorCobrado}
                                    onChange={(e) => setValorCobrado(e.target.value)}
                                />
                            </div>

                            <div className='dv-formapagto'>
                                <label htmlFor='form-pag'>Forma de pagamento</label>
                                <select id='form-pag' value={formaPagtoSelecionada} onChange={handleFormaSelecionada}>
                                    { formaPagamento.map( (forma, index) => {
                                        return(
                                            <option key={index} value={index}>{forma}</option>
                                        )
                                    })}
                                </select>
                            </div>

                            <div className='dv-formapagto'>
                                <label htmlFor='parcelas'>Parcelado em?</label>
                                <select id='parcelas' value={parcelaSelecionada} onChange={handleParcelaSelecionada}>
                                    { parcelas.map( (parcela, index) => {
                                        return(
                                            <option key={index} value={index}>{parcela}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        
                            <div className='dv-formapagto'>
                                <label htmlFor='dt-vencto'>Vencto a partir de</label>
                                <Input
                                    id='dt-vencto'
                                    type='date'
                                    value={dataVencimento}
                                    onChange={(e) => setDataVencimento(e.target.value)}
                                />
                            </div>                        
                       
                        </div>
                        
                        <div className='obs-pagamento'>
                            <label htmlFor="obs-pag">Observação sobre o pagamento</label>
                            <TextArea
                                id='obs-pag'
                                placeholder='Observação referente ao pagamento...'
                                value={obsPagamento}
                                onChange={ (e) => setObsPagamento(e.target.value)}
                            />
                        </div>

                    </div> 
                </div>
            </div>
        </section>
    )

}