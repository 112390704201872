import './index.css'
import { Link } from 'react-router-dom'
import img1 from '../../../assets/cadeira.jpg'
import img2 from '../../../assets/medico.jpg'
import img3 from '../../../assets/veterinario.jpg'
import img4 from '../../../assets/estetica.jpg'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { FormEvent } from 'react'

export default function BannerCarousel(){

    const handleDragStart = (e: FormEvent) => e.preventDefault();

    const items = [
      <img className='imgBanner' src={img1} alt="img1" height={350} width={570} onDragStart={handleDragStart} role="presentation" />,
      <img className='imgBanner' src={img2} alt="img2" height={350} width={570} onDragStart={handleDragStart} role="presentation" />,
      <img className='imgBanner' src={img3} alt="img3" height={350} width={570} onDragStart={handleDragStart} role="presentation" />,
      <img className='imgBanner' src={img4} alt="img4" height={350} width={570} onDragStart={handleDragStart} role="presentation" />
    ]

    return(
        <section className='containerBanner'>
            <div className='boxBanner'>
                <div className='boxImagem'>
                    <AliceCarousel 
                        autoPlay={true}
                        animationType='fadeout'
                        infinite={true}
                        animationDuration={3000}
                        items={items} 
                        disableButtonsControls={true}
                    />
                </div>                

                <div className='txtBanner'>
                    <span>
                        Conheça o sistema Minha clínica, um sistema para controle de pacientes, consultas, agendamentos, financeiro, podendo ser usado para consultas médicas, odontológicas, estéticas, veterinárias e muito mais 100% online.                       
                    </span>

                    <div className='boxbtn'>
                        <Link to='/cadastrouser' target='_blank'>
                            Experimente grátis por 7 dias
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}