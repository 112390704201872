import { useNavigate } from 'react-router-dom'

export default function ItensFinanceiro(){
    const navigate = useNavigate()

    return(
        <ul className='ul-itens'>
            <li>CONTAS A RECEBER</li>
            <li>CONTAS A PAGAR</li>
        </ul>
    )
}