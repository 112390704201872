import { useState, useEffect } from 'react'
import './index.css'
import Header from "../../components/Header"
import BarraLateral from '../../components/BarraLateral'
import Footer from '../../components/Footer'
import Loading from '../../components/Loading'
import { AiFillDashboard } from 'react-icons/ai'
import { FaUsers, FaUserAlt } from 'react-icons/fa'
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { setupApiClient } from '../../services/api'

type dadosDashProps = {
    quantPacientes: number
    pacientesHoje: number
}


export default function Dashboard(){
    const [isLoaging, setIsLoading] = useState(false)
    const [dadosDashboard, setDadosDashboard] = useState<dadosDashProps>(null)

    useEffect( () => {
        async function getCountPacientes() {
            
            const apiClient = setupApiClient()

            await apiClient.get('/clientecount')
            .then( (response) => {
                setDadosDashboard(response.data)
            })
            .catch( (err) => {
                console.log(err.data)                
            })
        }

        getCountPacientes()
    },[])

    if(isLoaging){
        return(
            <main>
                <Header/>
                <BarraLateral/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header/>
            <BarraLateral/>

            <section className='content'>
                <div className='div-home'>
                    <div className='div-container-btn'>
                        <div className='textheader'>
                            <AiFillDashboard size={25} color='#555'/>
                            <h3>Você está em dashboard</h3>
                        </div>               
                    </div>

                    <div className='container-dashboard'>

                        <div className='box-dash'>
                            <div className='info-pedido'>
                                <h3>{dadosDashboard?.pacientesHoje}</h3>
                                <FaUserAlt color="#00F" size={60}/>
                            </div>
                            <div className='legenda'>
                                <span>Pacientes hoje...</span>
                            </div>
                        </div>

                        <div className='box-dash'>
                            <div className='info-pedido receber'>
                                <h3>{dadosDashboard?.quantPacientes}</h3>
                                <FaUsers color="#396911" size={60}/>
                            </div>
                            <div className='legenda leg-receber'>
                                <span>Pacientes cadastrados...</span>
                            </div>
                        </div> 

                        <div className='box-dash'>
                            <div className='info-pedido servico'>
                                <h3>0</h3>
                                <GiReceiveMoney color="#ff9100" size={60}/>
                            </div>
                            <div className='legenda leg-servico'>
                                <span>Contas a receber hoje...</span>
                            </div>
                        </div> 

                        <div className='box-dash'>
                            <div className='info-pedido pagar'>
                                <h3>0</h3>
                                <GiPayMoney color="#af1313" size={60}/>
                            </div>
                            <div className='legenda leg-pagar'>
                                <span>Contas a pagar hoje...</span>
                            </div>
                        </div> 

                    </div>

                    <div className='img-logotipo'>
                        <img src={require('../../assets/logo.png')} alt="Logo" width='20%'/>
                    </div>
                </div>   
            </section>   

            <Footer/>
        </main>
    )
}