import './index.css'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { Input, TextArea } from '../../Input'
import { Button } from '../../Button'
import { FormEvent, useState } from 'react'
import { emailTest } from '../../../utils/utils'
import emailjs from '@emailjs/browser'
import { toast } from 'react-toastify'

export default function Localizacao(){
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [mensagem, setMensagem] = useState('')

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAPWO9XhbW0Jo52eQvtN6PHKRIlhtWGjxA"
    })

    const center = {
        lat: -22.4818344,
        lng: -47.4581839
    }

    function handleEnviar(e: FormEvent){
        e.preventDefault()

        if(nome === '' || email === '' || mensagem === ''){
            return
        }

        if(!emailTest.test(email)){
            return
        }

        const templateParams = {
            from_name: nome,
            message: mensagem,
            email: email
        }

        emailjs.send('service_kkdsqnu','template_z5vfa7h', templateParams,'lnLTYIUH_FKUiEXrQ')
        .then( () => {
            toast.success('Mensagem enviada!')
            setNome('')
            setEmail('')
            setMensagem('')
        })
    }

    return(
        <section className='localizacao'>
            <div className='container-localizacao'>
                <div className='texto-localizacao'>
                    <span>Entre em contato</span>
                    <form onSubmit={handleEnviar}>
                        <Input
                            id='nome'
                            type='text'
                            placeholder='Seu nome...'
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                        />

                        <Input
                            type='email'
                            placeholder='Seu e-mail...'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <TextArea
                            placeholder='Sua mensagem...'
                            value={mensagem}
                            onChange={(e) => setMensagem(e.target.value)}
                        />

                        <Button type='submit'>
                            Enviar
                        </Button>

                    </form>
                </div>

                <div className='map-localizacao'>
                    { isLoaded ? (
                            <GoogleMap
                              mapContainerStyle={{ width:'100%', height:'100%', borderRadius:'5px' }}
                              center={center}
                              zoom={15}
                            >
                                <Marker
                                    position={center}
                                />
                            </GoogleMap>
                        ) : <></>
                    }
                </div>
            </div>
        </section>
    )
}