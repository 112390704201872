import { useState } from 'react'
import './index.css'
import { TfiEmail } from 'react-icons/tfi'
import { BsTelephone } from 'react-icons/bs'
import { IoLocationOutline } from 'react-icons/io5'
import { GrFormNextLink } from 'react-icons/gr'
import { Link } from 'react-router-dom'
import { BiTime } from 'react-icons/bi'
import { emailTest } from '../../../utils/utils'
import { setupApiClient } from '../../../services/api'
import { toast } from 'react-toastify'

export default function FooterHome(){
    const [email, setEmail] = useState('')

    async function handleNewLetter(){
        if(!email){
            return
        }

        if(emailTest.test(email)){
            
            const setupClient = setupApiClient()

            await setupClient.post('/newsletter',{
                email: email
            }) 
            .then( () => {
                toast.success('e-mail cadastrado!')
                setEmail('')
            })
            .catch( () => {
                toast.warn('e-mail já cadastrado!')
            })
        }
    }

    return(
        <footer className='footer-page' id='contato'>
            <div className='box-footer-page'>

                <section className='footer-secao1'>
                    <h3>Contato</h3>
                    <span><TfiEmail color='#FFF' size={18}/>E-mail: contato@wgainformatica.com.br</span>
                    <span><BsTelephone color='#FFF' size={18}/>Telefone: +55 (19) 3546-5686</span>
                    <span><IoLocationOutline color='#FFF' size={18}/>Cidade: Cordeirópolis/SP - Brasil</span>
                </section>

                <section className='footer-secao2'>
                    <h3>Links úteis</h3>
                    <Link to='/'>Home</Link>
                    <Link to='/login' target='_blank'>Login</Link>
                    <Link to='/cadastrouser' target='_blank'>Cadastre-se</Link>
                    <Link to=''>Planos e preços</Link>
                </section>

                <section className='footer-secao3'>
                    <h3>Informações</h3>
                    <div className='boxbtn'>
                        <Link to='/cadastrouser' target='_blank'>
                            Experimente grátis por 7 dias
                        </Link>
                    </div>
                    <span id='info'><BiTime color='#FFF' size={18} />Horário de atendimento e suporte</span>
                    <span>Segunda a Sexta-Feira das 08:00 às 18:00 horas</span>
                    <span>Horário de Brasília - Dias úteis</span>
                </section>

                <section className='footer-secao4'>
                    <h3>Newsletter</h3>
                    <span>Cadastre-se e fique por dentro</span> 
                    <span>das nossas novidades e atualizações</span>
                    <span>recebendo em seu e-mail</span>
                    <div>
                        <input 
                            type='email' 
                            placeholder='E-mail'
                            autoComplete='none'
                            value={email}
                            onChange={ (e) => setEmail(e.target.value)}
                        />
                        <button onClick={handleNewLetter}>
                            <GrFormNextLink color='#000' size={25}/>
                        </button>
                    </div>
                </section>

            </div>

            <div className='footer-info'>
                <span>COPYRIGHT © {new Date().getFullYear()} <Link to='https://site.wgainformatica.com.br/' target='_blank'>WGA Informática</Link> - Todos os direitos reservados - Software para comércio em geral - Atendemos todo Brasil</span>
            </div>
        </footer>
    )
}