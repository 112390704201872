import { useContext } from 'react'
import { Routes, Route } from "react-router-dom";
import { AuthContext } from '../contexts/AuthContext';

import Home from "../pages/home";
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import ListaPaciente from "../pages/ListaPaciente";
import NovoPaciente from "../pages/NovoPaciente";
import Private from "./private";
import MinhaConta from '../pages/MinhaConta';
import CadastroUsuario from '../pages/CadastroUsuario';
import Agenda from '../pages/Agenda';
import Prontuario from '../pages/Prontuario';

import Notfound from "../pages/Notfound";

export default function RoutesApp(){
    const { diasUso, isPremium } = useContext(AuthContext)
    const idUser = localStorage.getItem('@MinhaClinica')

    return(
        <Routes>
            <Route path="/" element={ <Home/> }/>
            <Route path="/login" element={ idUser ? <Dashboard/> : <Login/> } />
            <Route path="/cadastrouser" element={ idUser ? <Dashboard/> : <CadastroUsuario/> } />

            <Route path="/dashboard" element={ <Private> {!isPremium && diasUso > 7 ? <MinhaConta/> : <Dashboard/> } </Private>  }/>
            <Route path="/pacientes" element={ <Private> {!isPremium && diasUso > 7 ? <MinhaConta/> : <ListaPaciente/> } </Private>  } />
            <Route path="/novopaciente" element={ <Private> {!isPremium && diasUso > 7 ? <MinhaConta/> : <NovoPaciente/> } </Private>  } />
            <Route path="/novopaciente/:id" element={ <Private> {!isPremium && diasUso > 7 ? <MinhaConta/> : <NovoPaciente/> }</Private>  } />
            <Route path="/account" element={ <Private> <MinhaConta/> </Private> } />
            <Route path="/agenda" element={ <Private> {!isPremium && diasUso > 7 ? <MinhaConta/> : <Agenda/> } </Private>  } />
            <Route path="/prontuario/:id" element={ <Private> {!isPremium && diasUso > 7 ? <MinhaConta/> : <Prontuario/> } </Private> } />

            <Route path="*" element={ <Notfound/> } />
        </Routes>
    )    
}