import { useState, useEffect } from 'react'
import './index.css'
import { FaWindowClose } from 'react-icons/fa'
import { BsFillCalendarCheckFill } from 'react-icons/bs'
import { TextArea } from '../Input'
import { setupApiClient } from '../../services/api'
import { toast } from 'react-toastify'
import { BiLoader } from 'react-icons/bi'
import { PacientesProps } from '../../pages/ListaPaciente'

interface ModalAgendaProps{
    date: string
    onClose: () => void
    atendimentoID?: AtendimentoProps
}

export interface AtendimentoProps{
    ID: string
    USUARIOID: string
    CLIENTEID: string
    DATA_AGENDA: string
    TRATAMENTO: string
    OBSERVACOES: string
}

export default function ModalAgenda({ date, onClose, atendimentoID }: ModalAgendaProps){

    const [loading, setLoading] = useState(false)
    const [pacientes, setPacientes] = useState<PacientesProps[]>([])
    const [pacienteSelecionado, setPacienteSelecionado] = useState(0)
    const [atendimento, setAtendimento] = useState(date)
    const [horaAtendimento, setHoraAtendimento] = useState('')
    const [tratamento, setTratamento] = useState('')
    const [observacoes, setObservacoes] = useState('')

    useEffect( () => {

        async function getClientes(){
            setLoading(true)

            try{
                const apiClient = setupApiClient()
    
                const response = await apiClient.get('/clientes')

                setPacientes(response.data)
                setLoading(false)
            } catch(err){
                console.log(err.data)
                toast.error('Erro buscando pacientes!')
                setLoading(false)
            }
            
        }

        getClientes()

    },[])

    useEffect( () => {
        if(atendimentoID){
            setTratamento(atendimentoID?.TRATAMENTO)
            setHoraAtendimento(atendimentoID?.DATA_AGENDA.substring(11,16))
            setObservacoes(atendimentoID?.OBSERVACOES)

            const idIndex = pacientes.findIndex( (paciente) => paciente?.ID === atendimentoID?.CLIENTEID)
            setPacienteSelecionado(idIndex)
        }
    },[pacientes])


    async function handleSalvar(){

        if(horaAtendimento === ''){
            toast.warn('Defina o horário do atendimento!')
            return
        }

        if(tratamento === ''){
            toast.warn('Descreva o tratamento a ser realizado!')
            return            
        }

        let dtAtendimento = atendimento.split('-')
        let hrAtendimento = horaAtendimento.split(':')
        let dataAtendimento = dtAtendimento[2]+'/'+dtAtendimento[1]+'/'+dtAtendimento[0]+'/'+hrAtendimento[0]+'/'+hrAtendimento[1]
        
        if(atendimentoID){
            try{
                const apiClient = setupApiClient()
    
                await apiClient.put('/agenda',{
                    id: atendimentoID?.ID,
                    clienteid: pacientes[pacienteSelecionado]?.ID,
                    data_agenda: dataAtendimento,
                    tratamento: tratamento,
                    observacoes: observacoes               
                })
    
                onClose()
                toast.success('Atendimento atualizado!')
            } catch(err){
                console.log(err)
                toast.error('Erro atualizando atendimento!')
            }              

        } else {
            try{
                const apiClient = setupApiClient()
    
                await apiClient.post('/agenda',{
                    clienteid: pacientes[pacienteSelecionado]?.ID,
                    data_agenda: dataAtendimento,
                    tratamento: tratamento,
                    observacoes: observacoes               
                })
    
                onClose()
                toast.success('Paciente agendado!')
            } catch(err){
                console.log(err)
                toast.error('Erro cadastrando atendimento!')
            }       
        }
    }

    function handlePacienteSelecionado(e){
        setPacienteSelecionado(e.target.value)
    }

    return(
        <main className='box-modal'>
            <div className='content-agenda'>
           
                <div className='grupo-btn-agenda'>
                    <button className='btnClose' onClick={onClose}>
                        <FaWindowClose size={20} color='#FFF'/>
                    </button>
                    
                    <button className='btnAgendar' onClick={handleSalvar}>
                        <BsFillCalendarCheckFill size={20} color='#FFF'/>
                        Confirmar
                    </button>
                </div>  

                <form className='form-agenda'>
                    <div className='header-agenda'>

                        <div className='nome-paciente'>
                            <span>Paciente</span>
                            { loading ? (
                                <div className='aguarde-paciente'>
                                    <span>
                                        <BiLoader color='#000' size={20}/>
                                        <strong>Carregando pacientes...</strong> 
                                        
                                    </span>
                                </div>
                            ) : (
                                <>
                                { pacientes.length > 0 && (
                                    <select value={pacienteSelecionado} onChange={handlePacienteSelecionado}>
                                        { pacientes.map( (item, index) => {
                                            return(
                                                <option key={item?.ID} value={index}>
                                                    {item?.NOME}
                                                </option>
                                            )
                                        })}
                                    </select> 
                                )}
                                </>
                            )}
                        </div>

                        <div className='data-atendimento'>
                            <span>Atendimento</span>
                            <input 
                                type="date" 
                                value={atendimento}
                                onChange={ (e) => setAtendimento(e.target.value)}
                            />
                            <input 
                                type="time" 
                                value={horaAtendimento}
                                onChange={ (e) => setHoraAtendimento(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='servico-executar'>
                        <span>Tratamento a realizar</span>
                        <TextArea
                            value={tratamento}
                            onChange={(e) => setTratamento(e.target.value)}
                        />
                    </div>

                    <div className='servico-executar'>
                        <span>Observações</span>
                        <TextArea
                            value={observacoes}
                            onChange={(e) => setObservacoes(e.target.value)}
                        />
                    </div>
                </form>
            </div>    

        </main>
    )
}