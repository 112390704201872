import { useState, useEffect, useContext } from 'react'
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Menu from "../../components/BarraLateral";
import { IoArrowBack } from 'react-icons/io5'
import { FaFileMedical, FaSearch } from 'react-icons/fa'
import { AiFillPrinter } from 'react-icons/ai'
import { useNavigate } from "react-router-dom";
import './index.css'
import { Button } from '../../components/Button';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { setupApiClient } from '../../services/api';
import ModalProntuario from '../../components/ModalProntuario';
import GerarProntuarioPDF from '../../reports/Prontuario';
import { AuthContext } from '../../contexts/AuthContext';

export type requestProntuariosProps = {
    DATA_AGENDA: string
    ID: string
    OBSERVACOES: string
    TRATAMENTO: string
    TRATAMENTO_REALIZADO: string
    CLIENTES:{
        NOME: string
    }
}

export default function Prontuario(){
    const { user } = useContext(AuthContext)
    const { id } = useParams()
    const navigate = useNavigate()

    const [isLoaging, setIsLoading] = useState(false)
    const [diasPesquisa, setDiasPesquisa] = useState(['60 DIAS','90 DIAS','6 MESES','1 ANO', 'TODOS'])
    const [periodoSelecionado, setPeriodoSelecionado] = useState(0)
    const [prontuarios, setProntuarios] = useState<requestProntuariosProps[]>([])
    const [detalheAtendimento, setDetalheAtendimento] = useState<requestProntuariosProps>()
    const [nome, setNome] = useState('')
    const [modalProntuario, setModalProntuario] = useState(false)

    useEffect( () => {
        getProntuarios()
    },[])

    async function getProntuarios(){
        try{
            setProntuarios([])
            setNome('')
            setIsLoading(true)
            const apiClient = setupApiClient()

            const response = await apiClient.get('/prontuarios',{
                params:{
                    clienteID: id,
                    dias: periodoSelecionado
                }
            })

            setProntuarios(response.data)
            setNome(response.data[0]?.CLIENTES?.NOME)
            setIsLoading(false)
           
        } catch(err){
            console.log(err.data)
            toast.error('Erro buscando prontuários! :(')
            setIsLoading(false)
        }
    }

    function handlePeriodo(e){
        setPeriodoSelecionado(e.target.value)
    }

    function handlePesquisar(){
        getProntuarios()
    }

    function handleDetalhes(atend: requestProntuariosProps){
        setDetalheAtendimento(atend)
        setModalProntuario(true)
    }

    function ImprimiProntuario(){
        let obj = {
            nomeClinica: user?.nome,
            caminhoLogo:'',
            dadosPaciente: prontuarios
        }

        GerarProntuarioPDF(obj)

    }

    if(isLoaging){
        return(
            <main>
                <Header/>
                <Menu/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header/>
            <Menu/>
            <section className='content'>
                <div className='div-home'>
                    <div className='div-container-btn'>
                        <div className='textheader'>
                            <button className='btnBack' onClick={ () => navigate('/pacientes')}>
                                <IoArrowBack color='#555' size={25}/>
                            </button>
                            <FaFileMedical color='#555' size={20}/>
                            <h3>Prontuário - {nome == undefined ? 'Paciente sem prontuário!' : nome}</h3>  
                        </div>               
                    </div>
                
                
                    <div className='divcontainer-dados'>
                        <div className='div-pesquisa'>
                            <p>Pesquisar prontuário</p>

                            <div className='div-filtropesquisa'>
                                <div>
                                    <label htmlFor="select-periodo">Últimos</label>
                                    <select value={periodoSelecionado} id="select-periodo" onChange={handlePeriodo}>
                                        { diasPesquisa.map( (periodo, index) => {
                                            return(
                                                <option key={index} value={index}>{periodo}</option>
                                            )
                                        })}
                                    </select>
                                </div>

                                <Button id='btnPesqPront' onClick={handlePesquisar}>
                                    <FaSearch color='#fff' size={15}/>
                                    Pesquisar
                                </Button>

                                <Button id='btnPrintPront' onClick={ImprimiProntuario}>
                                    <AiFillPrinter color='#fff' size={20}/>
                                    Imprimir
                                </Button>
                            </div>
                        </div>
                    </div>
                
                    <div className='div-container-cards'>
                        { prontuarios.map( (atend) => {

                            let data = atend?.DATA_AGENDA.split('-')
                            return(
                                <div key={atend?.ID} className='card'>
                                    <FaFileMedical color='blue' size={25}/> 
                                    <span>{data[2].substring(0,2)+'/'+data[1]+'/'+data[0]}</span>

                                    <button onClick={() => handleDetalhes(atend)}>
                                        Ver detalhes
                                    </button>    
                                </div> 
                            )
                        })}                
                    </div>

                
                
                </div>
                



            </section>

            { modalProntuario && (
                <ModalProntuario
                    atendimento={detalheAtendimento}
                    onClose={() => setModalProntuario(false)}
                />
            )}

            <Footer/>
        </main>
    )
}

