import './index.css'
import { useState, FormEvent } from 'react'
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import { FaRegLaughWink } from 'react-icons/fa'
import { HiUser } from 'react-icons/hi'
import { MdMarkEmailRead } from 'react-icons/md'
import { AiFillEyeInvisible } from 'react-icons/ai'
import { setupApiClient } from '../../services/api'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha'
import { emailTest } from '../../utils/utils'

export default function CadastroUsuario(){
    const navigate = useNavigate()

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [recaptcha, setRecaptcha] = useState('')
    const [loading, setLoading] = useState(false)


    async function handleCadastrar(e: FormEvent) {
        e.preventDefault()
        
        if(nome === '' || email === '' || senha === ''){
            toast.warn('Preencha todos os campos!')
            return
        }

        if(!recaptcha){
            toast.warn('Marque o captcha!')
            return;             
        }

        if(!emailTest.test(email)){
            toast.warn('E-mail inválido!')
            return
        }

        try{
            setLoading(true)

            const apiClient = setupApiClient()

            await apiClient.post('/cadastrouser',{
                nome: nome,
                email: email,
                password: senha
            })

            toast.success('Dados cadastrados com sucesso!')
            setLoading(false)
            navigate('/login')
        } catch(err){
            console.log(err)
            setLoading(false)
        }
    }

    function onRecaptcha(value: string){
        setRecaptcha(value)
    }

    return(
        <main className='boxContainer'>
            <div className='boxLogin boxcadastro'>

                <div className='boximg'>
                    <FaRegLaughWink color='#FFF' size={90}/>
                    <h3>Minha clínica</h3>
                    <span>Bem vindo(a) ao sistema</span>
                </div>

                <form className='boxform' onSubmit={handleCadastrar} >
                    <h4>Cadastro de acesso</h4>

                    <div className='boxemail'>
                        <HiUser size={35} color='#FFF'/>
                        <Input
                            type='text'
                            placeholder='Seu nome ou estabelecimento...'
                            value={nome}
                            onChange={ (e) => setNome(e.target.value)}
                        />
                    </div>

                    <div className='boxemail'>
                        <MdMarkEmailRead size={35} color='#FFF'/>
                        <Input
                            type='email'
                            placeholder='Seu email...'
                            value={email}
                            onChange={ (e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div className='boxemail'>
                        <AiFillEyeInvisible size={35} color='#FFF'/>
                        <Input
                            type='password'
                            placeholder='Sua senha...'
                            value={senha}
                            onChange={ (e) => setSenha(e.target.value)}
                        />
                    </div>

                    <div className='recaptcha'>
                        <ReCAPTCHA
                            theme='light'
                            sitekey="6LdZD2ElAAAAAIftCMwg4Y6Z0bMwy3L4iITcWRyr"
                            onChange={onRecaptcha}
                        />
                    </div>

                    <Button
                        type='submit'
                    >
                        { loading ? 'Aguarde...' : 'Cadastrar' }
                    </Button>

                    <Link to='/login'>
                        Já possuo uma conta! Fazer login
                    </Link>
                </form>

            </div>
        </main>
    )
}