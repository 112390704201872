import './index.css'
import { FaWindowClose } from 'react-icons/fa'
import { CgSmileSad } from 'react-icons/cg'
import { PacientesProps } from '../../pages/ListaPaciente'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { MdCancel } from 'react-icons/md'

interface ModalProps{
    data: PacientesProps
    onClose: () => void
    onRequest: (id: string) => Promise<void>
}

export default function ModalDelete({ data, onClose, onRequest }: ModalProps){
    return(
        <div className='box-modal'>
            <div className='content-modal'>
                
                <div className='btnCloseModal'>
                    <button onClick={onClose}>
                        <FaWindowClose size={20} color='#FFF'/>
                    </button>
                </div>                

                <div className='headerModal'>
                    <CgSmileSad size={60} color='#ED1C24'/>
                    <h3>Deseja excluir o paciente?</h3>
                    <h2>{data?.NOME}</h2>
                </div>               

                <div className='contentButton'>
                    <button id='ok' onClick={ () => onRequest(data?.ID)}>
                        <BsFillCheckCircleFill color='#FFF' size={16}/>
                        Excluir
                    </button>

                    <button id='cancel' onClick={onClose}>
                        <MdCancel color='#FFF' size={18}/>
                        Cancelar
                    </button>
                </div>            
            </div>
        </div>
    )
}