import { InputHTMLAttributes, TextareaHTMLAttributes } from 'react'
import './index.css'

interface InputProps extends InputHTMLAttributes<HTMLInputElement>{}

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement>{}

export function Input({...rest}: InputProps){
    return(
        <input 
            className='input'
            autoComplete='none'
            {...rest}
        />
    )
}

export function TextArea({...rest}: TextAreaProps){
    return(
        <textarea
            className='inputarea'
            {...rest}
        >

        </textarea>
    )
}