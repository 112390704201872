import { useContext, useState, useEffect } from 'react'
import './index.css'
import { FaWindowClose } from 'react-icons/fa'
import logo from '../../assets/fundologo.jpg'
import { PacientesProps } from '../../pages/ListaPaciente'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { AiFillPrinter } from 'react-icons/ai'
import { TextArea } from '../Input'
import { AuthContext } from '../../contexts/AuthContext'
import { BiLoaderCircle } from 'react-icons/bi'
import { setupApiClient } from '../../services/api'
import { toast } from 'react-toastify'
import GerarPDF from '../../reports/FichaAnamnese'

interface ModalAnamneseProps{
    onClose: () => void;
    data: PacientesProps
}

export default function ModalAnamnese({ onClose, data }: ModalAnamneseProps){
    const { user } = useContext(AuthContext)

    const [mes, setMes] = useState(["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"])
    const [isloading, setIsLoading] = useState(false)
    const [idFicha, setIdFicha] = useState('')
    const [dtCadastro, setDtCadastro] = useState(null)
    const [dtAtualizacao, setDtAtualizacao] = useState(null)
    const [sa1, setSa1] = useState('')
    const [sa2, setSa2] = useState('')
    const [sa3, setSa3] = useState('')
    const [sa4, setSa4] = useState('')
    const [sa5, setSa5] = useState('')
    const [sa6, setSa6] = useState('')
    const [sa7, setSa7] = useState('')
    const [sa8, setSa8] = useState('')
    const [sa9, setSa9] = useState('')
    const [sa10, setSa10] = useState('')
    const [sa11, setSa11] = useState('')
    const [sa12, setSa12] = useState('')
    const [sa13, setSa13] = useState('')

    const [saop1, setSaOp1] = useState(false)
    const [saop2, setSaOp2] = useState(false)
    const [saop3, setSaOp3] = useState(false)
    const [saop4, setSaOp4] = useState(false)
    const [saop5, setSaOp5] = useState(false)
    const [saop6, setSaOp6] = useState(false)
    const [saop7, setSaOp7] = useState(false)
    const [saop8, setSaOp8] = useState(false)
    const [saop9, setSaOp9] = useState(false)
    const [saop10, setSaOp10] = useState(false)
    const [saop11, setSaOp11] = useState(false)
    const [saop12, setSaOp12] = useState(false)
    const [saop13, setSaOp13] = useState(false)    
    const [saop14, setSaOp14] = useState(false)  
    const [saop15, setSaOp15] = useState(false)  
    const [saop16, setSaOp16] = useState(false)  
    const [saop17, setSaOp17] = useState(false) 
    
    const [od1, setOd1] = useState('')
    const [od2, setOd2] = useState('')
    const [od3, setOd3] = useState('')
    const [od4, setOd4] = useState('')
    const [od5, setOd5] = useState('')
    const [od6, setOd6] = useState('')
    const [od7, setOd7] = useState('')
    const [od8, setOd8] = useState('')
    const [od9, setOd9] = useState('')
    const [od10, setOd10] = useState('')
    const [od11, setOd11] = useState('')
    const [od12, setOd12] = useState('')
    const [od13, setOd13] = useState('')

    let dia = new Date().getDate()
    let mMes = mes[new Date().getMonth()]
    let ano = new Date().getFullYear()

    useEffect( () => {
        async function getDadosPaciente(){
            setIsLoading(true)

            try{
                const apiClient = setupApiClient()

                const response = await apiClient.get('/ficha-anamnese',{
                    params:{
                        clienteid: data?.ID
                    }
                })
                
                if(response.data){
                    const { ID, DATACADASTRO, ATUALIZADO, SA1, SA2, SA3, SA4, SA5, SA6, SA7, SA8, SA9, SA10, SA11, SA12, SA13,
                    SAOP1, SAOP2, SAOP3, SAOP4, SAOP5, SAOP6, SAOP7, SAOP8, SAOP9, SAOP10, SAOP11, SAOP12, SAOP13, SAOP14, SAOP15, SAOP16, SAOP17,
                    OD1, OD2, OD3, OD4, OD5, OD6, OD7, OD8, OD9, OD10, OD11, OD12, OD13 } = response.data

                    setIdFicha(ID)
                    setDtCadastro(new Date(DATACADASTRO).toLocaleDateString('pt-br'))
                    setDtAtualizacao(new Date(ATUALIZADO).toLocaleDateString('pt-br'))
                    setSa1(SA1)
                    setSa2(SA2)
                    setSa3(SA3)
                    setSa4(SA4)
                    setSa5(SA5)
                    setSa6(SA6)
                    setSa7(SA7)
                    setSa8(SA8)
                    setSa9(SA9)
                    setSa10(SA10)
                    setSa11(SA11)
                    setSa12(SA12)
                    setSa13(SA13)

                    setSaOp1(SAOP1)
                    setSaOp2(SAOP2)
                    setSaOp3(SAOP3)
                    setSaOp4(SAOP4)
                    setSaOp5(SAOP5)
                    setSaOp6(SAOP6)
                    setSaOp7(SAOP7)
                    setSaOp8(SAOP8)
                    setSaOp9(SAOP9)
                    setSaOp10(SAOP10)
                    setSaOp11(SAOP11)
                    setSaOp12(SAOP12)
                    setSaOp13(SAOP13)
                    setSaOp14(SAOP14)
                    setSaOp15(SAOP15)
                    setSaOp16(SAOP16)
                    setSaOp17(SAOP17)

                    setOd1(OD1)
                    setOd2(OD2)
                    setOd3(OD3)
                    setOd4(OD4)
                    setOd5(OD5)
                    setOd6(OD6)
                    setOd7(OD7)
                    setOd8(OD8)
                    setOd9(OD9)
                    setOd10(OD10)
                    setOd11(OD11)
                    setOd12(OD12)
                    setOd13(OD13)
                }

                setIsLoading(false)

            } catch(err){
                console.log(err)
                setIsLoading(false)
            }
        }

        getDadosPaciente()

    }, [])

    const objPDF = {
        nomeClinica: user?.nome,
        caminhoLogo: logo,
        dadosPaciente:{
            nome: data?.NOME,
            datacad: dtCadastro,
            data_at: dtAtualizacao,
            dataass: user?.nome +', ' + dia + ' de '+ mMes + ' de '+ ano,
            saudeGeral:{
                sa1: sa1,
                sa2: sa2,
                sa3: sa3,
                sa4: sa4,
                sa5: sa5,
                sa6: sa6,
                sa7: sa7,
                sa8: sa8,
                sa9: sa9,
                sa10: sa10,
                sa11: sa11,
                sa12: sa12,
                sa13: sa13,
            },
            saudeOP:{
                saop1: saop1,
                saop2: saop2,
                saop3: saop3,
                saop4: saop4,
                saop5: saop5,
                saop6: saop6,
                saop7: saop7,
                saop8: saop8,
                saop9: saop9,
                saop10: saop10,
                saop11: saop11,
                saop12: saop12,
                saop13: saop13,
                saop14: saop14, 
                saop15: saop15, 
                saop16: saop16, 
                saop17: saop17                
            },
            saudeOdon:{
                od1: od1,
                od2: od2,
                od3: od3,
                od4: od4,
                od5: od5,
                od6: od6,
                od7: od7,
                od8: od8,
                od9: od9,
                od10: od10,
                od11: od11,
                od12: od12,
                od13: od13,                
            }
        }
    }

    async function handleSalvar(){
        setIsLoading(true)

        try{
            if(idFicha === ''){
                const apiClient = setupApiClient()

                await apiClient.post('/ficha-anamnese',{
                    clienteid: data?.ID,
                    sa1: sa1,
                    sa2: sa2,
                    sa3: sa3,
                    sa4: sa4,
                    sa5: sa5,
                    sa6: sa6,
                    sa7: sa7,
                    sa8: sa8,
                    sa9: sa9,
                    sa10: sa10,
                    sa11: sa11,
                    sa12: sa12,
                    sa13: sa13,
                    saop1: saop1,
                    saop2: saop2,
                    saop3: saop3,
                    saop4: saop4,
                    saop5: saop5,
                    saop6: saop6,
                    saop7: saop7,
                    saop8: saop8,
                    saop9: saop9,
                    saop10: saop10,
                    saop11: saop11,
                    saop12: saop12,
                    saop13: saop13,
                    saop14: saop14,
                    saop15: saop15,
                    saop16: saop16,
                    saop17: saop17,
                    od1: od1,
                    od2: od2,
                    od3: od3,
                    od4: od4,
                    od5: od5,
                    od6: od6,
                    od7: od7,
                    od8: od8,
                    od9: od9,
                    od10: od10,
                    od11: od11,
                    od12: od12,
                    od13: od13
                })
                .then( () => {
                    toast.success('Ficha cadastrada com sucesso!')
                    onClose()
                })

            } else {
                const apiClient = setupApiClient()

                await apiClient.put('/ficha-anamnese',{
                    id: idFicha,
                    sa1: sa1,
                    sa2: sa2,
                    sa3: sa3,
                    sa4: sa4,
                    sa5: sa5,
                    sa6: sa6,
                    sa7: sa7,
                    sa8: sa8,
                    sa9: sa9,
                    sa10: sa10,
                    sa11: sa11,
                    sa12: sa12,
                    sa13: sa13,
                    saop1: saop1,
                    saop2: saop2,
                    saop3: saop3,
                    saop4: saop4,
                    saop5: saop5,
                    saop6: saop6,
                    saop7: saop7,
                    saop8: saop8,
                    saop9: saop9,
                    saop10: saop10,
                    saop11: saop11,
                    saop12: saop12,
                    saop13: saop13,
                    saop14: saop14,
                    saop15: saop15,
                    saop16: saop16,
                    saop17: saop17,
                    od1: od1,
                    od2: od2,
                    od3: od3,
                    od4: od4,
                    od5: od5,
                    od6: od6,
                    od7: od7,
                    od8: od8,
                    od9: od9,
                    od10: od10,
                    od11: od11,
                    od12: od12,
                    od13: od13
                })
                .then( () => {
                    toast.success('Ficha atualizada com sucesso!')
                    onClose()
                })
            }

        } catch(err){
            console.log(err.data)
            toast.error('Erro tentando salvar!')
            setIsLoading(false)
        }

        setIsLoading(false)
    }

    return(
        <section className='container-anamnese'>

            <div className='box-anamnese'>

                <div className='grupo-btn'>
                    <button id='cancelar' onClick={onClose}>
                        <FaWindowClose size={20} color='#FFF'/>
                    </button>

                    <button id='salvar' onClick={handleSalvar}>
                        <BsFillCheckCircleFill size={20} color='#FFF'/>
                        Salvar
                    </button>

                    <button id='imprimir' onClick={() => GerarPDF(objPDF)}>
                        <AiFillPrinter size={20} color='#FFF'/>
                        Imprimir
                    </button>

                    { isloading && (
                        <div className='load-ficha'>
                            <BiLoaderCircle color='#000' size={40}/>
                            <span>Aguarde...</span>
                        </div>
                    )}
                </div>                  
                
                <div className='txtLabel'>
                    <span>Ficha de Anamnese</span>
                </div>

                <div className='logo-clinica'>
                    <img id='imgToExport' src={ user?.url_logo ? user?.url_logo : logo} alt='Logo'/> 
                </div>

                <div className='box-info'>
                    <span>Paciente</span><i>{data?.NOME}</i>
                    <span>Data de cadastro</span><i>{dtCadastro ? dtCadastro : 'Não informado'}</i>
                    <span>Última atualização</span><i>{dtAtualizacao ? dtAtualizacao : 'Não informado'}</i>
                </div>

                <div className='box-saude'>
                    <span>Saúde Geral</span>

                    <div className='infoSaude'>
                        <ul>
                            <li>
                                Recebeu tratamento médico nos últimos 2 anos? <br/>
                                <span>Informe abaixo em que especialidade, motivo, nome do médico e quanto tempo hospitalizado!</span>
                                <TextArea
                                    value={sa1}
                                    onChange={ (e) => setSa1(e.target.value)}
                                />
                            </li>
                            <li>
                                Toma medicamentos, quais?
                                <TextArea
                                    value={sa2}
                                    onChange={ (e) => setSa2(e.target.value)}
                                />
                            </li>
                            <li>
                                Tem alergia a algum medicamento ou anestésico, qual?
                                <TextArea
                                    value={sa3}
                                    onChange={ (e) => setSa3(e.target.value)}                            
                                />
                            </li>
                            <li>
                                Já fez alguma cirurgia e cirurgias bucais, quando?
                                <TextArea
                                    value={sa4}
                                    onChange={ (e) => setSa4(e.target.value)}                                
                                />
                            </li>
                            <li>
                                Costuma sangrar muito quando se machuca e demora para cicatrizar?
                                <TextArea
                                    value={sa5}
                                    onChange={ (e) => setSa5(e.target.value)}                                
                                />
                            </li>
                            <li>
                                Já fez transfusão de sangue?
                                <TextArea
                                    value={sa6}
                                    onChange={ (e) => setSa6(e.target.value)}                                
                                />
                            </li>
                            <li>
                                Você fuma, quantos cigarros/dia?
                                <TextArea
                                    value={sa7}
                                    onChange={ (e) => setSa7(e.target.value)}                                
                                />
                            </li>
                            <li>
                                Toma bebidas alcoólicas frequentemente?
                                <TextArea
                                    value={sa8}
                                    onChange={ (e) => setSa8(e.target.value)}                                
                                />
                            </li>
                            <li>
                                Costuma a sentir tontura ou desmaio?
                                <TextArea
                                    value={sa9}
                                    onChange={ (e) => setSa9(e.target.value)}                                
                                />
                            </li>
                            <li>
                                Tem ou teve algumas doenças abaixo?
                                <div className='info-doenca'>
                                    <div>
                                        <ul style={{listStyle:'none'}}>
                                            <li> <input type='checkbox' checked={saop1} onChange={(e) => setSaOp1(e.target.checked)} /> Problemas cardíacos? </li>
                                            <li> <input type='checkbox' checked={saop2} onChange={(e) => setSaOp2(e.target.checked)} /> Hepatite? </li>
                                            <li> <input type='checkbox' checked={saop3} onChange={(e) => setSaOp3(e.target.checked)} /> Febre reumática? </li>
                                            <li> <input type='checkbox' checked={saop4} onChange={(e) => setSaOp4(e.target.checked)} /> Problemas nervosos? </li>
                                            <li> <input type='checkbox' checked={saop5} onChange={(e) => setSaOp5(e.target.checked)} /> Problemas respiratórios? </li>
                                            <li> <input type='checkbox' checked={saop6} onChange={(e) => setSaOp6(e.target.checked)} /> HIV? </li>
                                        </ul>
                                    </div>

                                    <div>
                                        <ul style={{listStyle:'none'}}>
                                            <li> <input type='checkbox' checked={saop7}  onChange={(e) => setSaOp7(e.target.checked)}  /> Pressão alta? </li>
                                            <li> <input type='checkbox' checked={saop8}  onChange={(e) => setSaOp8(e.target.checked)}  /> Diabetes? </li>
                                            <li> <input type='checkbox' checked={saop9}  onChange={(e) => setSaOp9(e.target.checked)}  /> Icterícia? </li>
                                            <li> <input type='checkbox' checked={saop10} onChange={(e) => setSaOp10(e.target.checked)} /> Hepático? </li>
                                            <li> <input type='checkbox' checked={saop11} onChange={(e) => setSaOp11(e.target.checked)} /> Tuberculose? </li>
                                            <li> <input type='checkbox' checked={saop12} onChange={(e) => setSaOp12(e.target.checked)} /> Outras? </li>
                                        </ul>
                                    </div>

                                    <div>
                                        <ul style={{listStyle:'none'}}>
                                            <li> <input type='checkbox' checked={saop13} onChange={(e) => setSaOp13(e.target.checked)} /> Anemia? </li>
                                            <li> <input type='checkbox' checked={saop14} onChange={(e) => setSaOp14(e.target.checked)} /> Epilipsia? </li>
                                            <li> <input type='checkbox' checked={saop15} onChange={(e) => setSaOp15(e.target.checked)} /> Reumatismo? </li>
                                            <li> <input type='checkbox' checked={saop16} onChange={(e) => setSaOp16(e.target.checked)} /> Problemas renais? </li>
                                            <li> <input type='checkbox' checked={saop17} onChange={(e) => setSaOp17(e.target.checked)} /> Depressão? </li>
                                            <li> Especifique no campo abaixo! </li>
                                        </ul>
                                    </div>
                                </div>
                                <TextArea
                                    value={sa10}
                                    onChange={ (e) => setSa10(e.target.value)}                                
                                />
                            </li>
                            <li>
                                Tem fobias?
                                <TextArea
                                    value={sa11}
                                    onChange={ (e) => setSa11(e.target.value)}                               
                                />
                            </li>     
                            <li>
                                Problemas alimentares?
                                <TextArea
                                    value={sa12}
                                    onChange={ (e) => setSa12(e.target.value)}                                
                                />
                            </li>  
                            <li>
                                Está grávida, quanto tempo?
                                <TextArea
                                    value={sa13}
                                    onChange={ (e) => setSa13(e.target.value)}                                
                                />
                            </li>                          
                        </ul>
                    </div>
                </div>

                <div className='box-saude'>
                    <span>Odontológico</span>
                    <div className='infoSaude'>
                        <ul>
                            <li>
                                Tem algum dente mole?
                                <TextArea
                                    value={od1}
                                    onChange={ (e) => setOd1(e.target.value)}                                 
                                />
                            </li>
                            <li>
                                Mastiga dos dois lados da boca?
                                <TextArea
                                    value={od2}
                                    onChange={ (e) => setOd2(e.target.value)}                                 
                                />
                            </li>
                            <li>
                                Seus dentes doem, quando?
                                <TextArea
                                    value={od3}
                                    onChange={ (e) => setOd3(e.target.value)}                                
                                />
                            </li>
                            <li>
                                Sente gosto ruim, mau hálito, quando?
                                <TextArea
                                    value={od4}
                                    onChange={ (e) => setOd4(e.target.value)}                                
                                />
                            </li>
                            <li>
                                Os alimentos se prendem entre os dentes, usa fio dental?
                                <TextArea
                                    value={od5}
                                    onChange={ (e) => setOd5(e.target.value)}                                 
                                />
                            </li>
                            <li>
                                Que tipo de escova dental usa, e escova quantas vezes ao dia?
                                <TextArea
                                    value={od6}
                                    onChange={ (e) => setOd6(e.target.value)}                                 
                                />
                            </li>
                            <li>
                                Sua gengiva sangra com facilidade, você já tratou?
                                <TextArea
                                    value={od7}
                                    onChange={ (e) => setOd7(e.target.value)}                                 
                                />
                            </li>
                            <li>
                                Você morde os lábios, objetos, costuma roer unhas ou tem outro costume?
                                <TextArea
                                    value={od8}
                                    onChange={ (e) => setOd8(e.target.value)}                                 
                                />
                            </li>
                            <li>
                                Repira normalmente pelo nariz, pela boca?
                                <TextArea
                                    value={od9}
                                    onChange={ (e) => setOd9(e.target.value)}                                 
                                />
                            </li>
                            <li>
                                Range os dentes quando dorme, costuma apertar os dentes?
                                <TextArea
                                    value={od10}
                                    onChange={ (e) => setOd10(e.target.value)}                                 
                                />
                            </li>
                            <li>
                                Ao abrir a boca sente dificuldade, dor ou ruído?
                                <TextArea
                                    value={od11}
                                    onChange={ (e) => setOd11(e.target.value)}                                 
                                />
                            </li>
                            <li>
                                Sente dor na articulação, face ou ouvido?
                                <TextArea
                                    value={od12}
                                    onChange={ (e) => setOd12(e.target.value)}                                
                                />
                            </li>
                            <li>
                                Teve ou tem algum problema de saúde não mencionado neste questionário ou alguma outra informação que julgue importante?
                                <TextArea
                                    value={od13}
                                    onChange={ (e) => setOd13(e.target.value)}                                 
                                />
                            </li>
                        </ul>
                    
                        <p>Declaro que as informações prestadas acima são verdadeiras!</p>
                        <p>Autorizo o tratamento odontológico, fotos e radiográfias necessárias para a manutenção e/ou recuperação da minha saúde bucal.</p>     

                        <p>{user?.nome}, {dia} de {mMes} de {ano}.</p>
             
                    </div>
                    <span>Assinatura do paciente ou responsável</span> 

                </div>
            </div>
        </section>
    )
}