import './index.css'
import { Link } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { AiFillCheckSquare } from 'react-icons/ai'

export default function Planos(){
    return(
        <section className='sec-planos' id='planos'>

            <div className='title-plano'>
                <span>Escolha seu plano</span>
            </div>

            <div className='container-planos'>

                <div className='box-plano1'>
                    <span id='span-free'>Grátis</span>
                    <small>Plano experimental</small>
                    <p>Experimente grátis por 7 dias.</p>
                    <span id='price'>R$ 0,00</span>

                    <Link to='/cadastrouser' target='_blank'>
                        Quero experimentar
                    </Link>
                    
                </div>

                <div className='box-plano2'>
                    <span>Iniciante</span>
                    <ul>
                        <li><AiFillCheckSquare color='green' size={20}/>Módulos limitados</li>
                        <li><AiFillCheckSquare color='green' size={20}/>Cadastro de pacientes</li>
                        <li><AiFillCheckSquare color='green' size={20}/>Agenda de atendimento</li>
                        <li><AiFillCheckSquare color='green' size={20}/>Opções limitadas</li>
                    </ul>

                    <span id='price-inic'>R$ 99,90<small>/mês</small></span>

                    <AnchorLink href='#contato'>
                        Entrar em contato
                    </AnchorLink>
                </div>

                <div className='box-plano3'>
                    <span>Avançado</span>
                    <ul>
                        <li><AiFillCheckSquare color='green' size={20}/>Módulos exclusivos</li>
                        <li><AiFillCheckSquare color='green' size={20}/>Cadastro de pacientes</li>
                        <li><AiFillCheckSquare color='green' size={20}/>Agendamento de pacientes</li>
                        <li><AiFillCheckSquare color='green' size={20}/>Módulo financeiro</li>
                        <li><AiFillCheckSquare color='green' size={20}/>Opções no sistema</li>
                        <li><AiFillCheckSquare color='green' size={20}/>Novas atualizações</li>
                        <li><AiFillCheckSquare color='green' size={20}/>Novos módulos inclusos</li>
                    </ul>

                    <span id='price-avanc'>R$ 149,90<small>/mês</small></span>

                    <AnchorLink href='#contato'>
                        Entrar em contato
                    </AnchorLink>                   
                </div>

            </div>



        </section>
    )
}