import { useState } from 'react'
import './index.css'
import MenuMobile from '../../components/Home/MenuMobile'
import HeaderHome from "../../components/Home/Header"
import BannerCarousel from '../../components/Home/Carousel'
import Funcionalidades from '../../components/Home/Funcionalidades'
import Planos from '../../components/Home/Planos'
import Depoimentos from '../../components/Home/Depoimentos'
import Localizacao from '../../components/Home/Localizacao'
import FooterHome from '../../components/Home/FooterHome'

export default function Home(){
    const [openMenuMobile, setOpenMenuMobile] = useState(false)

    return(
        <main className='mainContainer'>
            <HeaderHome
                openMenu={ () => setOpenMenuMobile(!openMenuMobile)}
            />

            { openMenuMobile && (
                <MenuMobile/>
            )}

            <BannerCarousel/>
            <Funcionalidades/>        
            <Planos/>
            <Depoimentos/>
            <Localizacao/>
            <FooterHome/>
        </main>
    )
}