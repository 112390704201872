import { useState, useContext, FormEvent, ChangeEvent, useEffect } from 'react'
import './index.css'
import Header from '../../components/Header'
import Menu from '../../components/BarraLateral'
import Footer from '../../components/Footer'
import { RiAccountCircleFill } from 'react-icons/ri'
import Loading from '../../components/Loading'
import img from '../../assets/avatar.png'
import fundologo from '../../assets/fundologo.jpg'
import { Input } from '../../components/Input'
import { Button } from '../../components/Button'
import { BsCheck } from 'react-icons/bs'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'
import { setupApiClient } from '../../services/api'
import { useNavigate } from 'react-router-dom'


export default function MinhaConta(){
    const navigate = useNavigate()
    const { user, isPremium, diasUso, updateUser } = useContext(AuthContext)

    const [isLoading, setIsLoading] = useState(false)
    const [nome, setNome] = useState(user?.nome)
    const [urlfoto, setUrlFoto] = useState(user?.urlfoto)
    const [urlLogo, setUrlLogo] = useState(user?.url_logo)
    const [senhaAnterior, setSenhaAnterior] = useState('')
    const [senhaNova, setSenhaNova] = useState('')
    const [confSenhaNova, setConfSenhaNova] = useState('')
    const [fotoUser, setFotoUser] = useState(null)
    const [logotipo, setLogoTipo] = useState(null)

    useEffect( () => {

        async function getDadosUser(){
            try{
                const apiClient = setupApiClient()

                const respUser = await apiClient.get('/me')
                const { ID, NOME, EMAIL, URLFOTO, URL_LOGO, ASSINATURAS } = respUser.data;

                let data = {
                    id: ID,
                    nome: NOME,
                    email: EMAIL,
                    urlfoto: URLFOTO,
                    url_logo: URL_LOGO,
                    plano: {
                        id: ASSINATURAS?.ID,
                        status: ASSINATURAS?.STATUS
                    }
                }
                
                updateUser(data)
                setNome(NOME)
                setUrlFoto(URLFOTO)

            } catch(err) {
                console.log(err)
            }

        }

        getDadosUser()

    },[])
    
    async function handleSalvar(e: FormEvent){
        e.preventDefault()

        if(nome === ''){
            toast.warn('Campo nome não pode ser vazio!')
            return
        }

        if(senhaAnterior !== ''){
            if(senhaNova === '' || confSenhaNova === ''){
                toast.warn('Para alterar a senha, digite a nova senha!')
                return
            }

            if(senhaNova !== confSenhaNova){
                toast.warn('Nova senha inválida, confirme a nova senha!')
                return
            }
        }

        try{
            setIsLoading(true)

            const req = new FormData();
            req.append('nome',nome.toUpperCase());
            req.append('password', senhaAnterior);
            req.append('newpassword', confSenhaNova)
            
            if (fotoUser){
                req.append('file', fotoUser)
            }   
            const apiClient = setupApiClient()

            await apiClient.put('/user', req)

            const respUser = await apiClient.get('/me')
            const { ID, NOME, EMAIL, URLFOTO, ASSINATURAS } = respUser.data;
                    
            let data = {
                id: ID,
                nome: NOME,
                email: EMAIL,
                urlfoto: URLFOTO,
                plano: {
                    id: ASSINATURAS?.ID,
                    status: ASSINATURAS?.STATUS
                }
            }

            updateUser(data)

            toast.success('Dados atualizados com sucesso!')
            navigate('/dashboard')
            setIsLoading(false)
        } catch(error) {
            console.log(error)
            toast.error('Erro atualizando a conta!, verifique os dados digitados!')
            setIsLoading(false)
        }
    }   
    
    function handleFoto(e: ChangeEvent<HTMLInputElement>){

        if(!e.target.files){
            return
        }

        const img = e.target.files[0];

        if (!img){
            return;
        }

        if (img.type === 'image/jpeg' || img.type === 'image/png' || img.type === 'image/jpg'){
            setFotoUser(img)
            setUrlFoto(URL.createObjectURL(img))
        }        

    }

    function handlelogotipo(e: ChangeEvent<HTMLInputElement>){

        if(!e.target.files){
            return
        }

        const img = e.target.files[0];

        if (!img){
            return;
        }

        if (img.type === 'image/jpeg' || img.type === 'image/png' || img.type === 'image/jpg'){
            setLogoTipo(img)
            setUrlLogo(URL.createObjectURL(img))
        }        

    }

    async function handleSalvarLogo(){
        try{
            setIsLoading(true)

            if(!logotipo){
                return
            }

            const req = new FormData();
            req.append('file', logotipo)

            const apiClient = setupApiClient()

            await apiClient.put('/logotipo', req)

            const respUser = await apiClient.get('/me')
            const { ID, NOME, EMAIL, URLFOTO, URL_LOGO, ASSINATURAS } = respUser.data;
                    
            let data = {
                id: ID,
                nome: NOME,
                email: EMAIL,
                urlfoto: URLFOTO,
                url_logo: URL_LOGO,
                plano: {
                    id: ASSINATURAS?.ID,
                    status: ASSINATURAS?.STATUS
                }
            }

            updateUser(data)

            toast.success('Logotipo atualizado com sucesso!')
            navigate('/dashboard')
            setIsLoading(false)
        } catch(error) {
            console.log(error)
            toast.error('Erro atualizando logotipo!')
            setIsLoading(false)
        }
    }

    if(isLoading){
        return(
            <main>
                <Header/>
                <Menu/>            
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main>
            <Header/>
            <Menu/>
            <div className='content'>
                <div className='div-home'>
                
                    <div className='div-container-btn'>
                        <div className='textheader'>
                            <RiAccountCircleFill size={25} color='#555'/>
                            <h3>Você está em minha conta</h3>
                        </div>               
                    </div>                
             
                    <div className='div-container-conta'>
                        <div className='boxImg'>
                            <label>
                                <input type='file' accept='image/jpeg, image/png' onChange={handleFoto}/>
                                <img src={ urlfoto ? urlfoto : img} alt='Avatar' width={120} />
                            </label>

                            <label>
                                <span className='txtLogo'>LOGOTIPO 500x140</span>
                                <input type='file' accept='image/jpeg, image/png' onChange={handlelogotipo}/>
                                <img id='logotipo' src={ urlLogo ? urlLogo : fundologo} alt='Logo' width={500} />
                                { logotipo && (
                                    <button className='btnLogo' onClick={handleSalvarLogo}>Salvar</button>
                                )}
                            </label>
                        </div>
                
                        <div className='container-inputs'>
                            <form className='boxInputs' onSubmit={handleSalvar}>
                            <Input
                                    value={nome}    
                                    placeholder='Nome'
                                    type='text'
                                    onChange={ (e) => setNome(e.target.value)}
                                /> 

                                <Input
                                    placeholder='email'
                                    type='email'
                                    disabled={true}
                                    value={user?.email}
                                /> 

                                <Input
                                    placeholder='Senha anterior'
                                    type='password'
                                    value={senhaAnterior}
                                    onChange={ (e) => setSenhaAnterior(e.target.value)}
                                /> 

                                <Input
                                    placeholder='Senha nova'
                                    type='password'
                                    value={senhaNova}
                                    onChange={ (e) => setSenhaNova(e.target.value)}
                                /> 

                                <Input
                                    placeholder='Confirmar senha nova'
                                    type='password'
                                    value={confSenhaNova}
                                    onChange={ (e) => setConfSenhaNova(e.target.value)}
                                /> 

                                <Button
                                    type='submit'
                                >
                                    <BsCheck color='#fff' size={25}/>
                                    Salvar
                                </Button>
                            </form>

                            <div className='boxConta'>
                                
                                { isPremium ? (
                                    <div className='boxPremium'>
                                        <span>Plano Atual - Premium</span>
                                        <small>Você já é Premium</small>
                                        <h6>Contato: +55 (19) 3546-5686</h6>
                                        <h6>email: contato@wgainformatica.com.br</h6>
                                    </div>
                                ) : (
                                    <div className='boxFree'>
                                        <span>Plano Atual - Free</span>
                                        <small>Vire Premium agora</small>

                                        {!isPremium && diasUso > 7 && (
                                        <div className='infoPlano'>
                                            <h5>Seu período de teste acabou,</h5>                             
                                            <h5>vire <i>Premium</i> agora!</h5>
                                        </div>
                                        )}

                                        <h6>Contato: +55 (19) 3546-5686</h6>
                                        <h6>email: contato@wgainformatica.com.br</h6>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>        
            </div>   
            <Footer/>
        </main>
    )
}