import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'
import  jwtDecode, { JwtPayload }  from 'jwt-decode'
import { parseCookies } from 'nookies'


export default function Private( { children } ){
    const { signOut } = useContext(AuthContext)

    const cookie = parseCookies();
    const token = cookie['@MinhaClinicaToken']

    if(!token){
        signOut()
        return <Navigate to='/'/>
    }

    const userToken = jwtDecode<JwtPayload>(token)
    const localID = localStorage.getItem('@MinhaClinica')

    if(userToken?.sub !== localID){
        signOut()
        return <Navigate to='/'/>       
    }
    
    return children
}