import './index.css'
import { Link } from "react-router-dom"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { AiFillHome, AiFillPhone } from 'react-icons/ai'
import { FaUserCheck, FaUserPlus } from 'react-icons/fa'
import { ImPriceTags } from 'react-icons/im'

export default function MenuMobile(){
    return(
        <nav className='box-menu-mobile'>
            <ul>
                <li> <AiFillHome color="#333333c7" size={15}/><Link to='/'>Home</Link></li>
                <li> <FaUserCheck color="#333333c7" size={16}/><Link to='/login' target='_blank'>Login</Link></li>
                <li> <FaUserPlus color="#333333c7" size={16}/><Link to='/cadastrouser' target='_blank'>Cadastre-se</Link></li>
                <li> <ImPriceTags color="#333333c7" size={15}/><AnchorLink href='#planos'>Planos e preços</AnchorLink></li>
                <li> <AiFillPhone color="#333333c7" size={16}/><AnchorLink href='#contato'>Contato</AnchorLink></li>
            </ul>
        </nav>
    )
}